import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import Loader from './include/Loader';
import Menu from './include/Menu';
import Footer from './include/Footer';
import { NavLink } from 'react-router-dom';
import axios from 'axios';
import { useHistory, useLocation } from 'react-router-dom';

const EditFaq = () => {
    useEffect(() => {
        document.getElementById('page-loader').style.display = 'none';

        var element = document.getElementById("page-container");
        element.classList.add("show");
    }, []);

    const history = useHistory();
    const location = useLocation();
    var record = location.state;

    const [faq, setFaq] = useState({
        question: record.question,
        answer: record.answer
    });

    const [disable, setDisable] = useState(false);
    const [errors, setErrors] = useState({});

    const handleFaq = (e) => {
        const basicData = { ...faq };
        basicData[e.target.name] = e.target.value;
        setFaq(basicData);
    }

    const handleSubmitFaq = (e) => {
        e.preventDefault();
        if (validate()) {
            setDisable(true);
            const myurl = "https://events.teambuildingawards.com:3000/api/admin/update-faq";
            var bodyFormData = new URLSearchParams();
            bodyFormData.append('question', faq.question);
            bodyFormData.append('answer', faq.answer);
            bodyFormData.append('faq_id', record.faq_id);
            axios({
                method: "post",
                url: myurl,
                data: bodyFormData,
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            }).then((response) => {
                console.log(response);
                if (response.data.success == true) {
                    setFaq('');
                    toast.success(response.data.message);
                    history.push('/admin/faq')
                }else{
                    setDisable(false);
                    toast.error(response.data.message);

                }
            }).catch((error) => {
                console.log("Errors", error);
            })
        }
    }

    const validate = () => {
        let input = faq;
    
        let errors = {};
        let isValid = true;

        if (!input['question']) {
            isValid = false;
            errors['question_err'] = 'Please enter question';
          }

          if (!input['answer']) {
            isValid = false;
            errors['answer_err'] = 'Please enter answer';
          }

        setErrors(errors);
        return isValid;
      };


    return (
        <>
            <Loader />
            <div id="page-container" className="fade page-sidebar-fixed page-header-fixed">
                <Menu />
                <div id="content" className="content">
                    <ol className="breadcrumb float-xl-right">
                        <li className="breadcrumb-item"><NavLink to="/admin/dashboard">Dashboard</NavLink></li>
                        <li className="breadcrumb-item"><NavLink to="/admin/master-categories">Master Categories</NavLink></li>
                        <li className="breadcrumb-item active">Edit Master Category</li>
                    </ol>
                    <h1 className="page-header">Edit Master Category  </h1>
                    <div className="panel panel-inverse" data-sortable-id="form-stuff-10">
                        <div className="panel-heading ui-sortable-handle">
                            <h4 className="panel-title">Edit Category</h4>
                            <NavLink to="/admin/master-categories" className="btn btn-sm btn-default pull-right">Back</NavLink>
                        </div>
                        <div className="panel-body">
                            <form
                  onSubmit={(e) => handleSubmitFaq(e)}
                  className="profileForm"
                >
                                <fieldset>
                                <div className='row'>
                                        <div className="col-md-4 form-group">
                                            <label htmlFor="exampleInputCity">Question:</label>
                                            <input type='text' className='form-control' name='question' onChange={handleFaq} value={faq.question}></input>
                                            <div className="text-danger">{errors.question_err}</div>
                                        </div>
                                        </div>

                                        <div className='row'>
                                        <div className="col-md-4 form-group">
                                            <label htmlFor="exampleInputCity">Answer:</label>
                                            <textarea type='text' className='form-control' name='answer' onChange={handleFaq} value={faq.answer}></textarea>
                                            <div className="text-danger">{errors.answer_err}</div>
                                        </div>
                                        </div>
                                        <div className='row'>
                                        <div className='col-md-4 form-group'>
                                            <button type='submit' className='btn btn-md btn-primary' style={{ fontSize: "15px", backgroundColor: '#f0c454' }} disabled={disable}>{disable ? 'Processing...' : 'Submit'}</button>
                                        </div>
                                    </div>
                                </fieldset>
                            </form>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    )
}

export default EditFaq