import React, { useState, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { TableHeader } from './Table';
import { Table } from 'react-bootstrap';
import axios from 'axios';
import { toast } from 'react-toastify';
import Loader from './include/Loader';
import Menu from './include/Menu';
import Footer from './include/Footer';

const ReviewDetails = () => {
    const location = useLocation();
    const record = location.state;
    const id = record.eventsData.event_id;
    const [contacts, setContacts] = useState([]);
    const [editingReviewId, setEditingReviewId] = useState(null);
    const [editedReview, setEditedReview] = useState({
        review_id: '',
        rating: '',
        name: '',
        comment: ''
    });

    const Header = [
        { name: 'Sr No.', field: 'sr_no', sortable: false },
        { name: 'Review id', field: 'review_id', sortable: false },
        { name: 'User id', field: 'user_id', sortable: false },
        { name: 'Rating', field: 'rating', sortable: false },
        { name: 'Name', field: 'name', sortable: false },
        { name: 'Comment', field: 'comment', sortable: false },
        { name: 'Edit', field: 'edit', sortable: false },
        { name: 'Delete', field: 'delete', sortable: false }
    ];

    const getContacts = () => {
        fetch(`https://events.teambuildingawards.com:3000/api/web/${id}/reviews`)
            .then((response) => response.json())
            .then((data) => setContacts(data));
    };

    useEffect(() => {
        getContacts();
        document.getElementById('page-loader').style.display = 'none';
        var element = document.getElementById("page-container");
        element.classList.add("show");
    }, [id]);

    const handleEditReview = (review) => {
        setEditingReviewId(review.review_id);
        setEditedReview({
            review_id: review.review_id,
            rating: review.rating,
            name: review.name,
            comment: review.comment
        });
    };

    const handleSaveEdit = (review) => {
        const myurl = "https://events.teambuildingawards.com:3000/api/web/review/update";
        axios.put(myurl, editedReview)
            .then((response) => {
                if (response.data.success === true) {
                    getContacts();
                    toast.success(response.data.message);
                    setEditingReviewId(null);
                }
            })
            .catch((error) => {
                console.log("Errors", error);
            });
    };

    const handleInputChange = (e, review) => {
        const { name, value } = e.target;
        setEditedReview(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleCancelEdit = () => {
        setEditingReviewId(null);
    };

    const handleDeleteReview = (review_id) => {
        if (window.confirm(`Do you want to remove this review?`)) {
            const myurl = "https://events.teambuildingawards.com:3000/api/web/review/delete";
            axios.delete(myurl, { data: { review_id } })
                .then((response) => {
                    if (response.data.success === true) {
                        getContacts();
                        toast.success(response.data.message);
                    }
                })
                .catch((error) => {
                    console.log("Errors", error);
                });
        }
    };



    const [newReview, setNewReview] = useState({
        event_id: id,
        user_id: '',
        rating: '',
        name: '',
        comment: ''
    });
    const handleInputChanges = (e) => {
        const { name, value } = e.target;
        setNewReview(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleAddReview = () => {
        axios.post("https://events.teambuildingawards.com:3000/api/web/add-review", newReview)
            .then((response) => {
                if (response.data.success === true) {
                    getContacts();
                    toast.success(response.data.message);
                    setNewReview({
                        event_id: id,
                        user_id: '',
                        rating: '',
                        name: '',
                        comment: ''
                    });
                } else {
                    toast.error(response.data.message);
                }
            })
            .catch((error) => {
                console.log("Errors", error);
                toast.error("An error occurred while adding the review.");
            });
    };


    return (
        <>
            <Loader />

            <div id="page-container" className="fade page-sidebar-fixed page-header-fixed">
                <Menu />

                <div id="content" className="content">
                    <ol className="breadcrumb float-xl-right">
                        <li className="breadcrumb-item">
                            <NavLink to="/admin/dashboard">
                                <span className="basePath">Dashboard</span>
                            </NavLink>
                        </li>
                        <li className="breadcrumb-item active currentPath">Reviews</li>
                    </ol>
                    <h1 className="page-header">Reviews</h1>
                    <br />
                    <div style={{ backgroundColor: 'white', padding: '20px', borderRadius: '20px', }}>
                        <div className="row w-100">
                            <div className="mb-3 col-12 text-center">
                                <div className="row ">
                                    <div className="col-12">
                                        <div className="table-responsive">
                                            <Table striped bordered hover>
                                                <thead>
                                                    <TableHeader headers={Header} />
                                                </thead>
                                                <tbody>
                                                    {contacts?.data?.map((val, i) => (
                                                        <tr key={i}>
                                                            <td>{i + 1}</td>
                                                            <td>{val.review_id}</td>
                                                            <td>{val.user_id}</td>
                                                            <td>{editingReviewId === val.review_id ? (
                                                                <input
                                                                    type="text"
                                                                    name="rating"
                                                                    value={editedReview.rating}
                                                                    onChange={(e) => handleInputChange(e, val)}
                                                                />
                                                            ) : (
                                                                val.rating
                                                            )}</td>
                                                            <td>{editingReviewId === val.review_id ? (
                                                                <input
                                                                    type="text"
                                                                    name="name"
                                                                    value={editedReview.name}
                                                                    onChange={(e) => handleInputChange(e, val)}
                                                                />
                                                            ) : (
                                                                val.name
                                                            )}</td>
                                                            <td>{editingReviewId === val.review_id ? (
                                                                <input
                                                                    type="text"
                                                                    name="comment"
                                                                    value={editedReview.comment}
                                                                    onChange={(e) => handleInputChange(e, val)}
                                                                />
                                                            ) : (
                                                                val.comment
                                                            )}</td>
                                                            <td>
                                                                {editingReviewId === val.review_id ? (
                                                                    <>
                                                                        <button className="btn btn-success btn-sm mr-2" onClick={() => handleSaveEdit(val)}>Save</button>
                                                                        <button className="btn btn-secondary btn-sm" onClick={handleCancelEdit}>Cancel</button>
                                                                    </>
                                                                ) : (
                                                                    <i className='fa fa-edit' style={{ color: "green", cursor: "pointer" }} onClick={() => handleEditReview(val)}></i>
                                                                )}
                                                            </td>
                                                            <td><i className='fa fa-trash' onClick={() => handleDeleteReview(val.review_id)} style={{ color: "red", cursor: "pointer" }}></i></td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-6 offset-md-3">
                                        <h2>Add Review</h2>
                                        <div className="form-group">
                                            <label>User ID</label>
                                            <input type="number" name="user_id" value={newReview.user_id} onChange={handleInputChanges} className="form-control" />
                                        </div>
                                        <div className="form-group">
                                            <label>Rating</label>
                                            <select name="rating" value={newReview.rating} onChange={handleInputChanges} className="form-control">
                                                <option value="">Select Rating</option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label>Name</label>
                                            <input type="text" name="name" value={newReview.name} onChange={handleInputChanges} className="form-control" />
                                        </div>
                                        <div className="form-group">
                                            <label>Comment</label>
                                            <input type="text" name="comment" value={newReview.comment} onChange={handleInputChanges} className="form-control" />
                                        </div>
                                        <button className="btn btn-primary" onClick={handleAddReview}>Add Review</button>
                                    </div>
                                </div>



                                <div
                                    className="mt-2 d-flex justify-content-sm-center justify-content-xs-center justify-content-lg-end"
                                    style={{
                                        overflowX: 'auto',
                                    }}
                                >

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Footer />
            </div>
        </>
    );
};

export default ReviewDetails;
