import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import Loader from './include/Loader';
import Menu from './include/Menu';
import Footer from './include/Footer';
import { NavLink } from 'react-router-dom';
import axios from 'axios';
import { useHistory, useLocation } from 'react-router-dom';

const ContactDetails = () => {
    useEffect(() => {
        document.getElementById('page-loader').style.display = 'none';

        var element = document.getElementById("page-container");
        element.classList.add("show");
    }, []);

    const history = useHistory();
    const location = useLocation();
    var record = location.state;

    const handleGoBack = () => {
        history.goBack();
      };

    return (
        <>
            <Loader />
            <div id="page-container" className="fade page-sidebar-fixed page-header-fixed">
                <Menu />
                <div id="content" className="content">
                    <ol className="breadcrumb float-xl-right">
                        <li className="breadcrumb-item"><NavLink to="/admin/dashboard">Dashboard</NavLink></li>
                        <li className="breadcrumb-item"><NavLink to="/admin/contacts">Contacts</NavLink></li>
                        <li className="breadcrumb-item active">View Contact Details</li>
                    </ol>
                    <h1 className="page-header">View Contact Details  </h1>
                    <div className="panel panel-inverse" data-sortable-id="form-stuff-10">
                        <div className="panel-heading ui-sortable-handle">
                            <h4 className="panel-title">View Contact Details</h4>
                            {/* <NavLink to="/admin/contacts" className="btn btn-sm btn-default pull-right">Back</NavLink> */}
                            <button onClick={handleGoBack} className="btn btn-sm btn-default pull-right">Back</button>
                        </div>
                        <div className="panel-body">
                            

                        <table class="table">
                            <tr>
                            <th style={{width:'25%'}}>Name</th>
                            <th style={{width:'75%'}}>{record.contactsData.first_name+' '+record.contactsData.last_name}</th>
                            </tr>
                            <tr>
                            <th>Email</th>
                            <td>{record.contactsData.email}</td>
                            </tr>
                            <tr>
                            <th>Phone Number</th>
                            <td>{record.contactsData.phone_number}</td>
                            </tr>
                            <tr>
                            <th>How we can help you</th>
                            <td>{record.contactsData.how_we_can_help_you}</td>
                            </tr>
                            <tr>
                            <th>Company Name</th>
                            <td>{record.contactsData.company_name}</td>
                            </tr>
                            <tr>
                            <th>Message</th>
                            <td>{record.contactsData.message}</td>
                            </tr>
                            <tr>
                            <th>Created At</th>
                            <td>{record.contactsData.createdAt}</td>
                            </tr>
                        </table>


                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    )
}

export default ContactDetails