import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import Loader from './include/Loader';
import Menu from './include/Menu';
import Footer from './include/Footer';
import { NavLink } from 'react-router-dom';
import axios from 'axios';
import { useHistory, useLocation } from 'react-router-dom';

const EventOrganiserHostsEdit = () => {
    useEffect(() => {
        document.getElementById('page-loader').style.display = 'none';

        var element = document.getElementById("page-container");
        element.classList.add("show");
    }, []);

    const history = useHistory();
    const location = useLocation();
    var record = location.state;

    const [category, setCategory] = useState();

    const [disable, setDisable] = useState(false);
    const [errors, setErrors] = useState({});

    const [bannerName, setBannerName] = useState({});

    const [formData, setFormData] = useState({
        host_id: record.host_info.host_id,
        first_name: record.host_info.first_name,
        last_name: record.host_info.last_name,
        email: record.host_info.email,
        phone_number: record.host_info.phone_number,
        company_name: record.host_info.company_name,
        acc_holder_name: record.host_info.acc_holder_name,
        acc_no: record.host_info.acc_no,
        ifsc_code: record.host_info.ifsc_code,
        bank_name: record.host_info.bank_name,
        branch_name: record.host_info.branch_name,
        paypal_id: record.host_info.paypal_id,
        image: record.host_info.image,
      });

    const handleInput = (e) => {;
        const basicData = { ...formData };
        basicData[e.target.name] = e.target.value;
        setFormData(basicData);
    }

    const handleSubmitHost = (e) => {
        e.preventDefault();
        if (validate()) {
            setDisable(true);
            const myurl = "https://events.teambuildingawards.com:3000/api/admin/update-host";
            var bodyFormData = new URLSearchParams();
            bodyFormData.append('host_id', formData.host_id);
            bodyFormData.append('first_name', formData.first_name);
            if(bannerName != formData.image){
                bodyFormData.append('image', bannerName);
            }
            bodyFormData.append('last_name', formData.last_name);
            bodyFormData.append('company_name', formData.company_name);
            bodyFormData.append('acc_holder_name', formData.acc_holder_name);
            bodyFormData.append('acc_no', formData.acc_no);
            bodyFormData.append('ifsc_code', formData.ifsc_code);
            bodyFormData.append('bank_name', formData.bank_name);
            bodyFormData.append('branch_name', formData.branch_name);
            bodyFormData.append('paypal_id', formData.paypal_id);

            axios({
                method: "post",
                url: myurl,
                data: bodyFormData,
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            }).then((response) => {
                console.log(response);
                if (response.data.success == true) {
                    setCategory('');
                    toast.success(response.data.message);
                    history.push('/admin/event-organiser-hosts')
                }else{
                    setDisable(false);
                    toast.error(response.data.message);

                }
            }).catch((error) => {
                console.log("Errors", error);
            })
        }
    }

    const validate = () => {
        let input = formData;
    
        let errors = {};
        let isValid = true;
  
          if (!input['first_name']) {
            isValid = false;
            errors['first_name_err'] = 'Please enter first name';
          }

          if (!input['last_name']) {
            isValid = false;
            errors['last_name_err'] = 'Please enter last name';
          }

        //   if (!input['email']) {
        //     isValid = false;
        //     errors['email_err'] = 'Please enter email';
        //   }else if (typeof input["email"] !== "undefined") {
        //     let lastAtPos = input["email"].lastIndexOf('@');
        //     let lastDotPos = input["email"].lastIndexOf('.');

        //     if (!(lastAtPos < lastDotPos && lastAtPos > 0 && input["email"].indexOf('@@') == -1 && lastDotPos > 2 && (input["email"].length - lastDotPos) > 2)) {
        //         isValid = false;
        //         errors["email_err"] = "Email is not valid";
        //     }
        // }

        //   if (!input['phone_number']) {
        //     isValid = false;
        //     errors['phone_number_err'] = 'Please enter phone number';
        //   }else if(input['phone_number'].length != 10){
        //     isValid = false;
        //     errors['phone_number_err'] = 'Please enter valid phone number';
        //   }

      

          if (!input['company_name']) {
            isValid = false;
            errors['company_name_err'] = 'Please enter company name';
          }


        setErrors(errors);
        return isValid;
      };

      const [{ alt, src }, setImg] = useState({
        src: record.host_info.image,
        alt: '',
      });

      const handleImg = (e) => {

        let img = new Image()
        img.src = window.URL.createObjectURL(e?.target?.files[0])
        img.onload = () => {
       // alert(img.width + " " + img.height);
        
        if(img.width <= 120 && img.height <= 120){

        if (e.target.files[0]) {
          setImg({
            src: URL.createObjectURL(e.target.files[0]),
            alt: e.target.files[0].name,
          });
          
        }
        setDisable(true);
       
        console.log('PHOTO===>', e?.target?.files[0]);

        const myurl = `https://events.teambuildingawards.com:3000/api/upload-img`;
        var bodyFormData = new FormData();
        bodyFormData.append('auth_code', 'Event#Cust$&$Resto#MD');
        bodyFormData.append('image', e?.target?.files[0]);
        //alert(bodyFormData);
        axios({
          method: 'post',
          url: myurl,
          data: bodyFormData,
        })
          .then((result) => {
            console.log('Success:=====', result);
            setBannerName(result?.data?.data?.filepath_url);
            setDisable(false);
            
          })
          .catch((error) => {
            console.error('Error:', error);
          });
        }else{
            toast.error("Please Upload max size 120 * 120");
        }
    }
      };


    return (
        <>
            <Loader />
            <div id="page-container" className="fade page-sidebar-fixed page-header-fixed">
                <Menu />
                <div id="content" className="content">
                    <ol className="breadcrumb float-xl-right">
                        <li className="breadcrumb-item"><NavLink to="/admin/dashboard">Dashboard</NavLink></li>
                        <li className="breadcrumb-item"><NavLink to="/admin/event-organiser-hosts">Event Organiser - Hosts</NavLink></li>
                        <li className="breadcrumb-item active">Edit Event Organiser - Hosts</li>
                    </ol>
                    <h1 className="page-header">Edit Event Organiser - Hosts  </h1>
                    <div className="panel panel-inverse" data-sortable-id="form-stuff-10">
                        <div className="panel-heading ui-sortable-handle">
                            <h4 className="panel-title">Edit Event Organiser - Hosts</h4>
                            <NavLink to="/admin/event-organiser-hosts" className="btn btn-sm btn-default pull-right">Back</NavLink>
                        </div>
                        <div className="panel-body">
                            <form
                  onSubmit={(e) => handleSubmitHost(e)}
                  className="profileForm"
                >
                                <fieldset>
                                <div className="row">
                                    <div className="col-md-4 form-group">
                                        <label for="inputFirstName" className="form-label">First Name <span style={{color:"red"}}>*</span></label>
                                        <input type="text" name="first_name" className="form-control" placeholder="Enter First Name" onChange={handleInput} value={formData.first_name} />
                                        <div className="text-danger">{errors.first_name_err}</div>
                                    </div>
                                    <div className="col-md-4 form-group">
                                        <label for="inputLastName" className="form-label">Last Name <span style={{color:"red"}}>*</span></label>
                                        <input type="text" name="last_name" className="form-control" placeholder="Enter Last Name" onChange={handleInput} value={formData.last_name} />
                                        <div className="text-danger">{errors.last_name_err}</div>
                                    </div>
                                    </div>
                                    <div className="row">
                                    <div className="col-md-4 form-group">
                                        <label for="inputEmail" className="form-label">Email Address <span style={{color:"red"}}>*</span></label>
                                        <input type="email" name="email" disabled className="form-control" placeholder="Enter Email Address" onChange={handleInput} value={formData.email} />
                                        
                                    </div>
                                    <div className="col-md-4 form-group">
                                        <label for="inputEmail" className="form-label">Phone Number <span style={{color:"red"}}>*</span></label>
                                        <input type="number" name="phone_number" disabled className="form-control" placeholder="Enter Phone Number" onChange={handleInput} value={formData.phone_number} />
                                        
                                    </div>
                                   </div>
                                   <div className="row">
                                    <div className="col-md-8 form-group">
                                        <label for="inputpasswprd" className="form-label">Company Name <span style={{color:"red"}}>*</span></label>
                                        <input type="text" name="company_name" className="form-control"
                                            placeholder="Enter Company Name" onChange={handleInput} value={formData.company_name} />
                                            <div className="text-danger">{errors.company_name_err}</div>
                                    </div>
                                    </div>

                                   
                                    <div className="row">
                                    <div className="col-md-8 form-group">
                                        <label className="form-label">Cover images</label>
                                            <input type="file" className="form-control" onChange={handleImg} />
                                        
                                            <small style={{color:'red'}}>Upload max size 120 * 120</small>
                                    </div>
                                    { src != "" ?
                                    <div className="col-12">
                                        <div className="tba-input-file-preview">
                                           
                                            <img
                        src={src}
                        className="img-fluid"
                        style={{width:"80px"}}
                        alt=""
                      />  
                      
                                        </div>
                                    </div>
                                    : 
                                    <img
                        src='assets/img/no-image.png'
                        className="img-fluid"
                        style={{width:"80px"}}
                        alt=""
                      />
                                    }
                               </div>

                                    <br/>
                        <h5>Bank Details</h5>

                                    <div className="row">
                            <div className="col-md-8 form-group">
                                <label className="form-label">Account Holder Name</label>
                                <input type="text" className="form-control" name="acc_holder_name" onChange={handleInput} value={formData.acc_holder_name} />
                               
                            </div>
                            </div>
                            

                            <div className="row">
                            <div className="col-md-4 form-group">
                                <label className="form-label">Account No</label>
                                <input type="text" className="form-control" name="acc_no" onChange={handleInput} value={formData.acc_no} />
                                
                            </div>
                            <div className="col-md-4 form-group">
                                <label className="form-label">IFSC Code</label>
                                <input type="text" className="form-control" name="ifsc_code" onChange={handleInput} value={formData.ifsc_code} />
                               
                            </div>
                            </div>
                            

                            <div className="row">
                            <div className="col-md-4 form-group">
                                <label className="form-label">Bank Name</label>
                                <input type="text" className="form-control" name="bank_name" onChange={handleInput} value={formData.bank_name} />
                                
                            </div>
                            <div className="col-md-4 form-group">
                                <label className="form-label">Branch Name</label>
                                <input type="text" className="form-control" name="branch_name" onChange={handleInput} value={formData.branch_name} />
                                
                            </div>
                            </div>
                            
                            <div className="row">
                            <div className="col-md-8 form-group">
                                <label for="inputState" className="form-label">Paypal ID</label>
                                <input type="text" name="paypal_id" onChange={handleInput} value={formData.paypal_id} className="form-control" />
                                </div>
                            </div>

                                
                                        <div className='row'>
                                        <div className='col-md-4 form-group'>
                                            <button type='submit' className='btn btn-md btn-primary' style={{ fontSize: "15px", backgroundColor: '#f0c454' }} disabled={disable}>{disable ? 'Processing...' : 'Submit'}</button>
                                        </div>
                                    </div>
                                </fieldset>
                            </form>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    )
}

export default EventOrganiserHostsEdit