import React, { useState, useEffect, useMemo } from 'react';
import { toast } from 'react-toastify';
import { NavLink, useHistory } from 'react-router-dom';
import Loader from './include/Loader';
import Menu from './include/Menu';
import Footer from './include/Footer';
import axios from 'axios';
import { TableHeader, Pagination, Search } from './Table';
import { Dropdown, Table } from 'react-bootstrap';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import moment from 'moment';
import 'moment-timezone';
import { format } from 'date-fns';

const EventOrganiserHosts = () => {
  let history = useHistory();

  const [disable, setDisable] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [eventOrganiserHosts, setEventOrganiserHosts] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState('');
  const [sorting, setSorting] = useState({ field: '', order: '' });
  const [limit, setlimit] = useState(10);

  const Header = [
    {
      name: 'Sr No.',
      field: 'sr_no',
      sortable: false,
    },
    {
      name: 'Name',
      field: 'name',
      sortable: false,
    },
    {
      name: 'Email',
      field: 'email',
      sortable: false,
    },
    {
      name: 'Phone Number',
      field: 'phone_number',
      sortable: false,
    },
    {
      name: 'Password',
      field: 'password',
      sortable: false,
    },
    {
      name: 'Otp',
      field: 'otp',
      sortable: false,
    },
    {
      name: 'Company Name',
      field: 'company_name',
      sortable: false,
    },
    {
      name: 'Form Type',
      field: 'form_type',
      sortable: false,
    },
    {
      name: 'Form',
      field: 'fw_nine_form',
      sortable: false,
    },
    {
      name: 'Created At',
      field: 'createdAt',
      sortable: false,
    },
    {
      name: 'Status',
      field: 'status',
      sortable: false,
    },
    {
      name: '',
      field: 'action',
      sortable: false,
    },
    {
      name: 'Action',
      field: 'action',
      sortable: false,
    },
  ];

  const getEventOrganiserHosts = () => {
    const myurl = "https://events.teambuildingawards.com:3000/api/admin/list-host";
    var bodyFormData = new URLSearchParams();
    bodyFormData.append('form_type', filterData.form_type);
    bodyFormData.append('status', filterData.status);
    bodyFormData.append('startDate', startDate);
    bodyFormData.append('endDate', endDate);
    axios({
      method: 'post',
      url: myurl,
      data: bodyFormData,
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    })
      .then((response) => {
        console.log(response['data']['data']);
        setDisable(false);
        var indexedData = response['data']['data']?.map((e, i) => {
          e = { ...e };
          e = { ...e, sr_no: i + 1 };
          e = {
            ...e,
            //createdAt: setDateFormat(e.createdAt),
            createdAt: moment.tz(e.createdAt, "America/New_York").format('MMMM D, YYYY, hh:mm A'),
            name: e.name ? e.name : 'N/A',
            email: e.email ? e.email : 'N/A',
            phone_number: e.phone_number ? e.phone_number : 'N/A',
            company_name: e.company_name ? e.company_name : 'N/A',
            password: e.password ? e.password : 'N/A',
            otp: e.otp ? e.otp : 'N/A',
          };
          return e;
        });
        setEventOrganiserHosts(indexedData);
      })
      .catch((error) => {
        console.log('Errors', error);
      });
  };

  useEffect(() => {
    getEventOrganiserHosts();

    document.getElementById('page-loader').style.display = 'none';

    var element = document.getElementById('page-container');
    element.classList.add('show');
  }, []);

  function setDateFormat(e) {
    var d = new Date(e);
    return (
      ('0' + d.getDate()).slice(-2) +
      '-' +
      ('0' + (d.getMonth() + 1)).slice(-2) +
      '-' +
      d.getFullYear() +
      ' ' +
      tConvert(
        ('0' + d.getHours()).slice(-2) + ':' + ('0' + d.getMinutes()).slice(-2)
      )
    );
  }
  function tConvert(time) {
    // Check correct time format and split into components
    time = time
      .toString()
      .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) {
      // If time format correct
      time = time.slice(1); // Remove full string match value
      time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join(''); // return adjusted time or original string
  }

  const commentsData = useMemo(() => {
    let computedComments = eventOrganiserHosts;

    if (search) {
      computedComments = computedComments.filter(
        (eventOrganiserHosts) =>
          eventOrganiserHosts.name.toLowerCase().includes(search.toLowerCase())
      );
    }
    setTotalItems(computedComments.length);

    //Sorting comments
    if (sorting.field) {
      const reversed = sorting.order === 'asc' ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * limit,
      (currentPage - 1) * limit + limit
    );
  }, [currentPage, search, sorting, eventOrganiserHosts, limit]);


  const handleStatusHost = (host_id, status) => {

    if (window.confirm("Do you want to change status this host?")) {
      const myurl = "https://events.teambuildingawards.com:3000/api/admin/host-status";
      var bodyFormData = new URLSearchParams();
      bodyFormData.append('host_id', host_id);
      bodyFormData.append('status', status == 1 ? 0 : 1);
      axios({
        method: "post",
        url: myurl,
        data: bodyFormData,
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      }).then((response) => {
        console.log(response);
        if (response.data.success == true) {
          getEventOrganiserHosts();
          toast.success(response.data.message);
        }
      }).catch((error) => {
        console.log("Errors", error);
      })
    }
  }

  const handleDeleteHost = (host_id) => {

    if (window.confirm(`Do you want to remove this host?`)) {
      const myurl = "https://events.teambuildingawards.com:3000/api/admin/host-status";
      var bodyFormData = new URLSearchParams();
      bodyFormData.append('host_id', host_id);
      bodyFormData.append('status', 2);
      axios({
        method: "post",
        url: myurl,
        data: bodyFormData,
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      }).then((response) => {
        console.log(response);
        if (response.data.success == true) {
          getEventOrganiserHosts();
          toast.success("Host Deleted Successfully");
        }
      }).catch((error) => {
        console.log("Errors", error);
      })
    }
  }


  const [filterData, setFilterData] = useState({
    form_type: '',
    status: '',
    created: ''
  });

  const handleInput = (e) => {
    ;
    const basicData = { ...filterData };
    basicData[e.target.name] = e.target.value;
    setFilterData(basicData);
  }

  const handleSearch = (e) => {
    setDisable(true);
    getEventOrganiserHosts();
  }

  const handleReset = () => {
    window.location.reload();
  }

  return (
    <>
      <Loader />

      <div
        id="page-container"
        className="fade page-sidebar-fixed page-header-fixed"
      >
        <Menu />

        <div id="content" className="content">
          <ol className="breadcrumb float-xl-right">
            <li className="breadcrumb-item">
              <NavLink to="/admin/dashboard">
                <span className="basePath">Dashboard</span>
              </NavLink>
            </li>
            <li className="breadcrumb-item active currentPath">Event Organiser - Hosts</li>
          </ol>
          <h1 className="page-header">Event Organiser - Hosts</h1>

          <div
            style={{
              backgroundColor: 'white',
              padding: '20px',
              borderRadius: '20px',
            }}
          >
            <div className="row w-100">
              <div className="col-12 text-center">
                <h6 style={{ textAlign: "left" }}>Filter For Event Organiser - Hosts:</h6>
                <div className="row">
                  <div className="col-md-2 form-group">
                    <select className="form-control" name="form_type" onChange={handleInput} value={filterData.form_type}>
                      <option value="">Select Form Type</option>
                      <option value="1">Form W-9</option>
                      <option value="2">Form W-8BEN</option>
                      <option value="0">None</option>
                    </select>
                  </div>

                  <div className="col-md-2 form-group">
                    <select className="form-control" name="status" onChange={handleInput} value={filterData.status}>
                      <option value="">Select Status</option>
                      <option value="1">Active</option>
                      <option value="0">Inactive</option>
                    </select>
                  </div>

                  <div className="col-md-2 form-group">
                    <DatePicker className="form-control" placeholderText="Start Created Date" selected={startDate} onChange={(date) => setStartDate(date)} maxDate={new Date()} dateFormat="MMMM dd,yyyy" />
                  </div>

                  <div className="col-md-2 form-group">
                    <DatePicker className="form-control" placeholderText="End Created Date" selected={endDate} onChange={(date) => setEndDate(date)} maxDate={new Date()} dateFormat="MMMM dd,yyyy" />
                  </div>

                  <div className="col-md-3" style={{ textAlign: "left" }}>
                    <button type='button' className='btn btn-md btn-primary m-t-2' style={{ cursor: "pointer", backgroundColor: '#f0c454' }} disabled={disable} onClick={handleSearch} >{disable ? 'Processing...' : 'Search'}</button>
                    <button type='button' className='btn btn-md btn-primary m-t-2' style={{ cursor: "pointer", backgroundColor: '#f0c454', marginLeft: "10px" }} onClick={handleReset} >Reset</button>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <br />

          <div
            style={{
              backgroundColor: 'white',
              padding: '20px',
              borderRadius: '20px',
            }}
          >
            <div className="row w-100">
              <div className="mb-3 col-12 text-center">
                <div className="row">
                  <div className="col-xl-6 col-lg-6 col-sm-6 col-12 mb-3">
                    <div className="ml-0">
                      <div className="d-flex">
                        <h5 className="mt-2 mr-1">Search: </h5>
                        <Search
                          onSearch={(value) => {
                            setSearch(value);
                            setCurrentPage(1);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-sm-6 col-12 d-flex justify-content-end mb-3">
                    <div
                      style={{
                        color: 'black',
                        fontSize: '12px',
                        fontWeight: '300',
                        paddingTop: '0px',
                        paddingBottom: '0px',
                      }}
                      className="align-self-center"
                    >
                      <b>Rows per page :&nbsp;</b>
                    </div>
                    <div className="align-self-center">
                      <Dropdown>
                        <Dropdown.Toggle
                          variant="none"
                          id="dropdown-basic"
                          style={{
                            cursor: 'auto',
                            backgroundColor: 'white',
                            borderColor: '#d5dbe0',
                            paddingBottom: '3px',
                            paddingTop: '3px',
                          }}
                        >
                          {limit}&nbsp;<i class="fa fa-caret-down"></i>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          {limit !== 10 ? (
                            <>
                              <Dropdown.Item
                                onClick={() => {
                                  setlimit(10);
                                }}
                              >
                                10
                              </Dropdown.Item>
                            </>
                          ) : null}

                          {limit !== 20 ? (
                            <>
                              <Dropdown.Item
                                onClick={() => {
                                  setlimit(20);
                                }}
                              >
                                20
                              </Dropdown.Item>
                            </>
                          ) : null}

                          {limit !== 30 ? (
                            <>
                              <Dropdown.Item
                                onClick={() => {
                                  setlimit(30);
                                }}
                              >
                                30
                              </Dropdown.Item>
                            </>
                          ) : null}

                          {limit !== 50 ? (
                            <>
                              <Dropdown.Item
                                onClick={() => {
                                  setlimit(50);
                                }}
                              >
                                50
                              </Dropdown.Item>
                            </>
                          ) : null}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                </div>

                <div className="row ">
                  <div className="col-12">
                    <div className="table-responsive">
                      <Table striped bordered hover>
                        <thead>
                          <TableHeader
                            headers={Header}
                            onSorting={(field, order) =>
                              setSorting({ field, order })
                            }
                          />
                        </thead>
                        <tbody>
                          {commentsData.map((e, i) => (

                            <tr>
                              <td>{e.sr_no}</td>
                              <td>{e.name}</td>
                              <td>{e.email}</td>
                              <td>{e.phone_number}</td>
                              <td>{e.password}</td>
                              <td>{e.otp}</td>
                              <td>{e.company_name}</td>
                              <td>{e.form_type == 1 ? 'Form W-9' : e.form_type == 2 ? 'Form W-8BEN' : '-'}</td>
                              <td>
                                {
                                  e.fw_nine_form != '-' ?
                                    <a href={e.fw_nine_form} target="_blank">
                                      <i
                                        className="fa fa-file"
                                        style={{ cursor: 'pointer', marginRight: '12px' }}
                                      ></i>
                                    </a>
                                    : '-'
                                }
                              </td>
                              <td>{e.createdAt}</td>
                              <td>
                                {
                                  e.is_active == 1 ?
                                    <label className="label label-success">Active</label>
                                    :
                                    <label className="label label-danger">Inactive</label>
                                }
                              </td>

                              <td>

                                <div class="custom-control custom-switch custom-switch-sm" style={{ paddingLeft: '10px' }}>
                                  <input type="checkbox" class="custom-control-input" id={e.host_id} data-id={e.host_id} checked={e.is_active == 1 ? 'checked' : ''} onClick={() => handleStatusHost(e.host_id, e.is_active)} />
                                  <label class="custom-control-label" for={e.host_id}></label>
                                </div>

                              </td>
                              <td>
                                <i
                                  className="fa fa-pen edit"
                                  title="Edit"
                                  style={{ cursor: 'pointer', marginRight: '12px' }}
                                  onClick={() => {
                                    history.push({
                                      pathname: '/admin/edit-event-organiser-hosts',
                                      state: {
                                        host_info: e,
                                      },
                                    });
                                  }}
                                ></i>
                                <i className='fa fa-trash' onClick={() => handleDeleteHost(e.host_id)} title="Delete" style={{ color: "red", cursor: "pointer" }}></i>

                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>
                <div
                  className="mt-2 d-flex justify-content-sm-center justify-content-xs-center justify-content-lg-end"
                  style={{
                    overflowX: 'auto',
                  }}
                >
                  <Pagination
                    total={totalItems}
                    itemsPerPage={limit}
                    currentPage={currentPage}
                    onPageChange={(page) => setCurrentPage(page)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};

export default EventOrganiserHosts;
