import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import Loader from './include/Loader';
import Menu from './include/Menu';
import Footer from './include/Footer';
import { NavLink } from 'react-router-dom';
import axios from 'axios';
import { useHistory, useLocation } from 'react-router-dom';
import parse from "html-react-parser";

import moment from 'moment';
import 'moment-timezone';
import { format } from 'date-fns';

import { ImageGroup, Image } from 'react-fullscreen-image'

import { Fancybox as NativeFancybox } from "@fancyapps/ui/dist/fancybox.esm.js";
import "@fancyapps/ui/dist/fancybox.css";

const EventsDetails = () => {


  function Fancybox(props) {
    const delegate = props.delegate || "[data-fancybox]";

    useEffect(() => {
      const opts = props.options || {};

      NativeFancybox.bind(delegate, opts);

      return () => {
        NativeFancybox.destroy();
      };
    }, []);

    return <>{props.children}</>;
  }

  useEffect(() => {
    document.getElementById('page-loader').style.display = 'none';

    var element = document.getElementById("page-container");
    element.classList.add("show");
  }, []);

  const history = useHistory();
  const location = useLocation();
  var record = location.state;

  function renderEventDuration(eventDuration) {
    try {
      const eventDurationArray = JSON.parse(eventDuration);
      if (Array.isArray(eventDurationArray)) {
        return eventDurationArray.map(item => (
          <span key={item.duration}>
            {item.duration} Minutes,
          </span>
        ));
      } else if (typeof eventDuration === 'string') {
        return <span>{eventDuration} Minutes</span>;
      } else {
        return <span>Unknown</span>;
      }
    } catch (error) {
      console.error('Error parsing event duration:', error);
      return <span>Unknown</span>;
    }
  }
  function renderEventPrice(eventPrice) {
    try {
      const eventPriceArray = JSON.parse(eventPrice);
      if (Array.isArray(eventPriceArray)) {
        return eventPriceArray.map(item => (
          <span key={item.price}>
            ${item.price}/
          </span>
        ));
      } else if (typeof eventPrice === 'string') {
        return <span>${eventPrice}</span>;
      } else {
        return <span>Unknown</span>;
      }
    } catch (error) {
      console.error('Error parsing event price:', error);
      return <span>Unknown</span>;
    }
  }
  return (
    <>
      <Loader />
      <div id="page-container" className="fade page-sidebar-fixed page-header-fixed">
        <Menu />
        <div id="content" className="content">
          <ol className="breadcrumb float-xl-right">
            <li className="breadcrumb-item"><NavLink to="/admin/dashboard">Dashboard</NavLink></li>
            <li className="breadcrumb-item"><NavLink to="/admin/events">Events</NavLink></li>
            <li className="breadcrumb-item active">View Event Details</li>
          </ol>
          <h1 className="page-header">View Event Details  </h1>
          <div className="panel panel-inverse" data-sortable-id="form-stuff-10">
            <div className="panel-heading ui-sortable-handle">
              <h4 className="panel-title">View Event Details</h4>
              <NavLink to="/admin/events" className="btn btn-sm btn-default pull-right">Back</NavLink>
            </div>
            <div className="panel-body">

              <div className='row'>
                <div className='col-md-8'>
                  <table class="table">
                    <tr>
                      <th>Image</th>
                      <td>
                        <Fancybox>

                          {record.eventsData.image.match('undefined') != 'undefined' ? (
                            <a data-fancybox="gallery" href={record.eventsData.image}>
                              <img
                                src={record.eventsData.image}
                                width="70px"
                                height="60px"
                                alt="customer"
                              />
                            </a>
                          ) : (
                            <img
                              src="./assets/img/icon/customer.png"
                              width="50px"
                              height="50px"
                              alt="customer"
                            />
                          )}

                          {record.eventsData.image_one != '' ? (
                            <a data-fancybox="gallery" href={record.eventsData.image_one}>
                              <img
                                src={record.eventsData.image_one}
                                width="70px"
                                height="60px"
                                alt="customer"
                                style={{ marginLeft: '12px' }}
                              />
                            </a>
                          ) : ''}

                          {record.eventsData.image_two != '' ? (
                            <a data-fancybox="gallery" href={record.eventsData.image_two}>
                              <img
                                src={record.eventsData.image_two}
                                width="70px"
                                height="60px"
                                alt="customer"
                                style={{ marginLeft: '12px' }}
                              />
                            </a>
                          ) : ''}

                          {record.eventsData.image_three != '' ? (
                            <a data-fancybox="gallery" href={record.eventsData.image_three}>
                              <img
                                src={record.eventsData.image_three}
                                width="70px"
                                height="60px"
                                alt="customer"
                                style={{ marginLeft: '12px' }}
                              />
                            </a>
                          ) : ''}

                          {record.eventsData.image_four != '' ? (
                            <a data-fancybox="gallery" href={record.eventsData.image_four}>
                              <img
                                src={record.eventsData.image_four}
                                width="70px"
                                height="60px"
                                alt="customer"
                                style={{ marginLeft: '12px' }}
                              />
                            </a>
                          ) : ''}

                        </Fancybox>
                      </td>
                    </tr>
                    <tr>
                      <th style={{ width: '25%' }}>Category Name</th>
                      <th style={{ width: '75%' }}>{record.eventsData.category_name}</th>
                    </tr>
                    <tr>
                      <th>Host Name</th>
                      <td>{record.eventsData.host_name}</td>
                    </tr>
                    <tr>
                      <th>Host Email</th>
                      <td>{record.eventsData.host_email}</td>
                    </tr>
                    <tr>
                      <th>Host Phone Number</th>
                      <td>{record.eventsData.host_phone_number}</td>
                    </tr>
                    <tr>
                      <th>Title</th>
                      <td>{record.eventsData.title}</td>
                    </tr>
                    <tr>
                      <th>Short Description</th>
                      <td>{record.eventsData.short_description}</td>
                    </tr>
                    <tr>
                      <th>Description</th>
                      <td>{parse(record.eventsData.description)}</td>
                    </tr>
                    <tr>
                      <th>Phone Number</th>
                      <td>{record.eventsData.phone_number}</td>
                    </tr>
                    <tr>
                      <th>No. of Guests</th>
                      <td>{record.eventsData.no_of_guests}</td>
                    </tr>
                    <tr>
                      <th>Minimum No. of People</th>
                      <td>{record.eventsData.min_guests}</td>
                    </tr>
                    <tr>
                      <th>Price per Guest</th>
                      <td>{renderEventPrice(record.eventsData.event_duration)}</td>
                    </tr>
                    <tr>
                      <th>Duration</th>
                      <td>{renderEventDuration(record.eventsData.event_duration)}</td>
                    </tr>
                    <tr>
                      <th>Event Type</th>
                      <td>{record.eventsData.category_name}</td>
                    </tr>
                    <tr>
                      <th>How it Works</th>
                      <td>{parse(record.eventsData.how_it_works)}</td>
                    </tr>
                    <tr>
                      <th>Things Guests will need</th>
                      <td>{parse(record.eventsData.things_guests_need)}</td>
                    </tr>
                    <tr>
                      <th>Created At</th>
                      <td>{moment(record.eventsData.createdAt).format('MMMM D, YYYY, hh:mm A')}</td>
                    </tr>
                  </table>
                </div>
                <div className='col-md-4'>
                  <table class="table">

                    <tr>
                      <th colSpan={2}><b>General Availability</b></th>
                    </tr>

                    <tr>
                      <th>Monday</th>
                      <td>{record.eventsData.schedule.monday_close == 1 ? "Close" : moment(record.eventsData.schedule.monday_start, 'hh:mm A').format('hh:mm A') + " - " + moment(record.eventsData.schedule.monday_end, 'hh:mm A').format('hh:mm A')}</td>
                    </tr>

                    <tr>
                      <th>Tuesday	</th>
                      <td>{record.eventsData.schedule.tuesday_close == 1 ? "Close" : moment(record.eventsData.schedule.tuesday_start, 'hh:mm A').format('hh:mm A') + " - " + moment(record.eventsData.schedule.tuesday_end, 'hh:mm A').format('hh:mm A')}</td>
                    </tr>

                    <tr>
                      <th>Wednesday</th>
                      <td>{record.eventsData.schedule.wednesday_close == 1 ? "Close" : moment(record.eventsData.schedule.wednesday_start, 'hh:mm A').format('hh:mm A') + " - " + moment(record.eventsData.schedule.wednesday_end, 'hh:mm A').format('hh:mm A')}</td>
                    </tr>

                    <tr>
                      <th>Thursday</th>
                      <td>{record.eventsData.schedule.thursday_close == 1 ? "Close" : moment(record.eventsData.schedule.thursday_start, 'hh:mm A').format('hh:mm A') + " - " + moment(record.eventsData.schedule.thursday_end, 'hh:mm A').format('hh:mm A')}</td>
                    </tr>

                    <tr>
                      <th>Friday</th>
                      <td>{record.eventsData.schedule.friday_close == 1 ? "Close" : moment(record.eventsData.schedule.friday_start, 'hh:mm A').format('hh:mm A') + " - " + moment(record.eventsData.schedule.friday_end, 'hh:mm A').format('hh:mm A')}</td>
                    </tr>

                    <tr>
                      <th>Saturday</th>
                      <td>{record.eventsData.schedule.saturday_close == 1 ? "Close" : moment(record.eventsData.schedule.saturday_start, 'hh:mm A').format('hh:mm A') + " - " + moment(record.eventsData.schedule.saturday_end, 'hh:mm A').format('hh:mm A')}</td>
                    </tr>

                    <tr>
                      <th>Sunday</th>
                      <td>{record.eventsData.schedule.sunday_close == 1 ? "Close" : moment(record.eventsData.schedule.sunday_start, 'hh:mm A').format('hh:mm A') + " - " + moment(record.eventsData.schedule.sunday_end, 'hh:mm A').format('hh:mm A')}</td>
                    </tr>
                  </table>
                </div>
              </div>

            </div>
          </div>


        </div>
        <Footer />
      </div>
    </>
  )
}

export default EventsDetails