import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import Loader from './include/Loader';
import Menu from './include/Menu';
import Footer from './include/Footer';
import { NavLink } from 'react-router-dom';
import axios from 'axios';
import { useHistory } from 'react-router-dom';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const CMSManagement = () => {
    useEffect(() => {
        document.getElementById('page-loader').style.display = 'none';

        var element = document.getElementById("page-container");
        element.classList.add("show");

    }, []);

    const [disable, setDisable] = useState(false);
    const [errors, setErrors] = useState({});

    const [formData, setFormData] = useState({
        term_condition: '',
        privacy_policy: '',
        about_us: '',
      });

      const [termCondition, setTermCondition] = useState("");
      const [privacyPolicy, setPrivacyPolicy] = useState("");
      
      const handleterm_condition = (e,editor)=>{
        setTermCondition(editor.getData());
    }

    const handleprivacy_policy = (e,editor)=>{
        setPrivacyPolicy(editor.getData());
    }

    const handleabout_us = (e,editor)=>{
        setFormData({about_us:editor.getData()});
    }
    
    const history = useHistory();
    const handleInput = (e) => {;
        const basicData = { ...formData };
        basicData[e.target.name] = e.target.value;
        setFormData(basicData);
    }

    const getCMS = (e) => {
        const myurl = "https://events.teambuildingawards.com:3000/api/admin/get-cms";
            var bodyFormData = new URLSearchParams();
            bodyFormData.append('id', 1);
            axios({
                method: "post",
                url: myurl,
                data: bodyFormData,
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            }).then((response) => {
                console.log(response);
                if (response.data.success == true) {
                    setFormData({
                        term_condition: response?.data?.data?.term_condition,
                        privacy_policy: response?.data?.data?.privacy_policy,
                        about_us: response?.data?.data?.about_us,
                      });

                      setTermCondition(response?.data?.data?.term_condition);
                      setPrivacyPolicy(response?.data?.data?.privacy_policy);
                }
                
            }).catch((error) => {
                console.log("Errors", error);
                setDisable(false);
            })
      }

    const handleSubmitBanner = (e) => {
        e.preventDefault();
        if (validate()) {
            setDisable(true);
            const myurl = "https://events.teambuildingawards.com:3000/api/admin/add-cms";
            var bodyFormData = new URLSearchParams();
            bodyFormData.append('term_condition', termCondition);
            bodyFormData.append('privacy_policy', privacyPolicy);
            bodyFormData.append('about_us', formData.about_us);
            axios({
                method: "post",
                url: myurl,
                data: bodyFormData,
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            }).then((response) => {
                console.log(response);
                if (response.data.success == true) {
                    toast.success(response.data.message);
                    setDisable(false);
                }
            }).catch((error) => {
                console.log("Errors", error);
                setDisable(false);
            })
        }
    }

    const validate = () => {
        let input = formData;
        
        let errors = {};
        let isValid = true;
  
          if (!termCondition) {
            isValid = false;
            errors['term_condition_err'] = 'Please enter term condition';
          }

          if (!privacyPolicy) {
            isValid = false;
            errors['privacy_policy_err'] = 'Please enter privacy policy';
          }

        //   if (!input['about_us']) {
        //     isValid = false;
        //     errors['about_us_err'] = 'Please enter about us';
        //   }

        setErrors(errors);
        return isValid;
      };

      useEffect(() => {

        getCMS();

    }, []);

    console.log("RST",termCondition);

    return (
        <>
            <Loader />
            <div id="page-container" className="fade page-sidebar-fixed page-header-fixed">
                <Menu />
                <div id="content" className="content">
                    <ol className="breadcrumb float-xl-right">
                        <li className="breadcrumb-item"><NavLink to="/admin/dashboard">Dashboard</NavLink></li>
                        <li className="breadcrumb-item active">CMS Management</li>
                    </ol>
                    <h1 className="page-header">CMS Management  </h1>
                    <div className="panel panel-inverse" data-sortable-id="form-stuff-10">
                        <div className="panel-heading ui-sortable-handle">
                            <h4 className="panel-title">CMS Management</h4>
                            <NavLink to="/admin/dashboard" className="btn btn-sm btn-default pull-right">Back</NavLink>
                        </div>
                        <div className="panel-body">
                        <form
                  onSubmit={(e) => handleSubmitBanner(e)}
                  className="profileForm"
                >
                                <fieldset>
                                    
                        <div className='row'>
                                        <div className="col-md-12 form-group">
                                            <label htmlFor="exampleInputterm_condition">Term Condition:</label>
                                            {/* <textarea type='text' rows="5" className='form-control' name='term_condition' onChange={handleInput} value={formData.term_condition}></textarea> */}
                                            <CKEditor id="editor" name="term_condition" data={termCondition} editor={ClassicEditor} onChange={(e,editor) => {handleterm_condition(e,editor)}} style={{ borderRadius: '8px' }} />
                                            <div className="text-danger">{errors.term_condition_err}</div>
                                        </div>

                                        <div className="col-md-12 form-group">
                                            <label htmlFor="exampleInputprivacy_policy">Privacy Policy:</label>
                                            {/* <textarea type='text' rows="5" className='form-control' name='privacy_policy' onChange={handleInput} value={formData.privacy_policy}></textarea> */}
                                            <CKEditor id="editor" name="privacy_policy" data={privacyPolicy} editor={ClassicEditor} onChange={(e,editor) => {handleprivacy_policy(e,editor)}}  style={{ borderRadius: '8px' }} />
                                            <div className="text-danger">{errors.privacy_policy_err}</div>
                                        </div>

                                        {/* <div className="col-md-12 form-group">
                                            <label htmlFor="exampleInputabout_us">About Us:</label>
                                            <textarea type='text' rows="5" className='form-control' name='about_us' onChange={handleInput} value={formData.about_us}></textarea> */}
                                            {/* <CKEditor id="editor" name="about_us" editor={ClassicEditor} onChange={(e,editor) => {handleabout_us(e,editor)}}  style={{ borderRadius: '8px' }} /> */}
                                            {/* <div className="text-danger">{errors.about_us_err}</div>
                                        </div> */}

                                        </div>
                                        <div className='row'>
                                        <div className='col-md-6 form-group'>
                                            <button type='submit' className='btn btn-md btn-primary m-t-30' style={{ fontSize: "15px", backgroundColor: '#f0c454' }} disabled={disable}>{disable ? 'Processing...' : 'Submit'}</button>
                                        </div>
                                    </div>
                                </fieldset>
                            </form>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    )
}

export default CMSManagement