import React from 'react'

function Loader() {
    return (
        <>
            <div id="page-loader" className="fade show">
         <span className="spinner"></span>
      </div>
        </>
    )
}

export default Loader
