import { Switch, Route, Redirect } from 'react-router-dom';
import Login from './components/admin/Login';
import ForgotPassword from './components/admin/ForgotPassword';
import Dashboard from './components/admin/Dashboard';
import Profile from './components/admin/Profile';


import Banners from './components/admin/Banners';
import AddBanner from './components/admin/AddBanner';

import Partners from './components/admin/Partners';
import AddPartner from './components/admin/AddPartner';

import CMSManagement from './components/admin/CMSManagement';
import Settings from './components/admin/Settings';
import Category from './components/admin/Category';
import AddCategory from './components/admin/AddCategory';
import EditCategory from './components/admin/EditCategory';

import MasterCategory from './components/admin/MasterCategory';
import AddMasterCategory from './components/admin/AddMasterCategory';
import EditMasterCategory from './components/admin/EditMasterCategory';

import EventOrganiserHosts from './components/admin/EventOrganiserHosts';
import EventOrganiserHostsEdit from './components/admin/EventOrganiserHostsEdit';
import Events from './components/admin/Events';
import EventsMagicShow from './components/admin/EventsMagicShow';
import EventsDetails from './components/admin/EventsDetails';
import EventGuests from './components/admin/EventGuests';

import EventQuestions from './components/admin/EventQuestions';

import Contacts from './components/admin/Contacts';
import ContactDetails from './components/admin/ContactDetails';

import Guests from './components/admin/Guests';
import ParticipatedGuests from './components/admin/ParticipatedGuests';

import Faq from './components/admin/Faq';
import AddFaq from './components/admin/AddFaq';
import EditFaq from './components/admin/EditFaq';

import Earning from './components/admin/Earning';
import Giftcontact from './components/admin/Giftcontact';
import Giftcontactdetail from './components/admin/Giftcontactdetail';
import ReviewDetails from './components/admin/ReviewDetails';

function App() {
  return (
    <Switch>
      <Route path="/team-building-awards-admin" exact component={Login} />
      <Route path='/admin/forgot-password' exact component={ForgotPassword} />
      <Route path="/admin/admin-profile" exact component={Profile} />
      <Route path="/admin/dashboard" exact component={Dashboard} />

      <Route path='/admin/banners' exact component={Banners} />
      <Route path='/admin/add-banner' exact component={AddBanner} />

      <Route path='/admin/partners' exact component={Partners} />
      <Route path='/admin/add-partner' exact component={AddPartner} />

      <Route path='/admin/cms-management' exact component={CMSManagement} />

      <Route path='/admin/settings' exact component={Settings} />

      <Route path='/admin/categories' exact component={Category} />
      <Route path='/admin/add-category' exact component={AddCategory} />
      <Route path='/admin/edit-category' exact component={EditCategory} />

      <Route path='/admin/master-categories' exact component={MasterCategory} />
      <Route path='/admin/add-master-category' exact component={AddMasterCategory} />
      <Route path='/admin/edit-master-category' exact component={EditMasterCategory} />

      <Route path='/admin/event-organiser-hosts' exact component={EventOrganiserHosts} />
      <Route path='/admin/edit-event-organiser-hosts' exact component={EventOrganiserHostsEdit} />
      <Route path='/admin/events' exact component={Events} />
      <Route path='/admin/eventsmagicshow' exact component={EventsMagicShow} />
      <Route path='/admin/event-details' exact component={EventsDetails} />
      <Route path='/admin/event-guests' exact component={EventGuests} />

      <Route path='/admin/event-questions' exact component={EventQuestions} />
      
      <Route path='/admin/contacts' exact component={Contacts} />
      <Route path='/admin/contact-details' exact component={ContactDetails} />

      <Route path='/admin/guests' exact component={Guests} />

      <Route path='/admin/participated-guests' exact component={ParticipatedGuests} />
      
      <Route path='/admin/earning' exact component={Earning} />

      <Route path='/admin/faq' exact component={Faq} />
      <Route path='/admin/add-faq' exact component={AddFaq} />
      <Route path='/admin/edit-faq' exact component={EditFaq} />

      <Route path='/admin/gift-contacts' exact component={Giftcontact} />
      <Route path='/admin/contact-gift-details' exact component={Giftcontactdetail} />
      <Route path='/admin/review-details' exact component={ReviewDetails} />

      <Redirect to="/team-building-awards-admin" />
    </Switch>
  );
}

export default App;