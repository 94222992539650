import React, { useState, useEffect, useMemo } from 'react';
import { toast } from 'react-toastify';
import { NavLink, useHistory } from 'react-router-dom';
import Loader from './include/Loader';
import Menu from './include/Menu';
import Footer from './include/Footer';
import axios from 'axios';
import { TableHeader, Pagination, Search } from './Table';
import { Dropdown, Table } from 'react-bootstrap';

import moment from 'moment';
import 'moment-timezone';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const Events = () => {
  let history = useHistory();

  const [disable, setDisable] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");


  const [masterCategories, setMasterCategories] = useState({});
  const [categories, setCategories] = useState({});

  const [events, setEvents] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState('');
  const [sorting, setSorting] = useState({ field: '', order: '' });
  const [limit, setlimit] = useState(10);
  const Header = [
    {
      name: 'Sr No.',
      field: 'sr_no',
      sortable: false,
    },
    {
      name: 'Image',
      field: 'image',
      sortable: false,
    },
    {
      name: 'Category Name',
      field: 'category_name',
      sortable: false,
    },
    {
      name: 'Host Info',
      field: 'host_name',
      sortable: false,
    },
    {
      name: 'Title',
      field: 'title',
      sortable: false,
    },
    {
      name: 'Host Phone No.',
      field: 'phone_number',
      sortable: false,
    },
    {
      name: 'No. of Guests',
      field: 'no_of_guests',
      sortable: false,
    },
    {
      name: 'Price per Guest',
      field: 'price_per_guest',
      sortable: false,
    },
    {
      name: 'Duration',
      field: 'event_duration',
      sortable: false,
    },
    // {
    //   name: 'Event Date/Time',
    //   field: 'start_date',
    //   sortable: false,
    // },
    {
      name: 'Created At',
      field: 'createdAt',
      sortable: false,
    },
    {
      name: 'Review',
      field: 'Review',
      sortable: false,
    },
    {
      name: 'Status',
      field: 'status',
      sortable: false,
    },
    {
      name: 'Action',
      field: 'action',
      sortable: false,
    },
  ];


  const handleStatusEvent = (event_id, status) => {

    if (window.confirm("Do you want to change status this event?")) {
      const myurl = "https://events.teambuildingawards.com:3000/api/admin/event-status";
      var bodyFormData = new URLSearchParams();
      bodyFormData.append('event_id', event_id);
      bodyFormData.append('status', status == 1 ? 0 : 1);
      axios({
        method: "post",
        url: myurl,
        data: bodyFormData,
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      }).then((response) => {
        console.log(response);
        if (response.data.success == true) {
          getEvents();
          toast.success(response.data.message);
        }
      }).catch((error) => {
        console.log("Errors", error);
      })
    }
  }

  const getEvents = () => {
    const myurl = "https://events.teambuildingawards.com:3000/api/admin/list-event";
    var bodyFormData = new URLSearchParams();
    bodyFormData.append('price', filterData.price);
    bodyFormData.append('duration', filterData.duration);
    bodyFormData.append('group', filterData.group);
    bodyFormData.append('master_category_id', filterData.master_category_id);
    bodyFormData.append('category', filterData.category);
    bodyFormData.append('startDate', startDate);
    bodyFormData.append('endDate', endDate);
    axios({
      method: 'post',
      url: myurl,
      data: bodyFormData,
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    })
      .then((response) => {
        setDisable(false);
        console.log(response['data']['data']);
        var indexedData = response['data']['data']?.map((e, i) => {
          e = { ...e };
          e = { ...e, sr_no: i + 1 };
          e = {
            ...e,
            //createdAt: setDateFormat(e.createdAt),
            createdAt: moment.tz(e.createdAt, "America/New_York").format('MMMM D, YYYY, hh:mm A'),
            image: e.image ? e.image : 'N/A',
            category_name: e.category_name ? e.category_name : 'N/A',
            host_name: e.host_name ? e.host_name : 'N/A',
            title: e.title ? e.title : 'N/A',
            phone_number: e.phone_number ? e.phone_number : 'N/A',
            no_of_guests: e.no_of_guests ? e.no_of_guests : 'N/A',
            price_per_guest: e.price_per_guest ? e.price_per_guest : 'N/A',
            event_duration: e.event_duration ? e.event_duration : 'N/A',
            start_date: e.start_date ? e.start_date : 'N/A',

          };
          return e;
        });
        setEvents(indexedData);
      })
      .catch((error) => {
        console.log('Errors', error);
      });
  };

  useEffect(() => {
    getEvents();

    document.getElementById('page-loader').style.display = 'none';

    var element = document.getElementById('page-container');
    element.classList.add('show');
    //getCategoryData();
    getMasterCategoryData();
  }, []);

  const getMasterCategoryData = (e) => {

    const myurl = "https://events.teambuildingawards.com:3000/api/admin/list-master-category";
    var bodyFormData = new URLSearchParams();
    bodyFormData.append('cat', 'refresh');
    axios({
      method: "post",
      url: myurl,
      data: bodyFormData,
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    }).then((response) => {
      //console.log("AF",response?.data?.data);
      setMasterCategories(response?.data?.data);

    }).catch((error) => {
      console.log("Errors", error);
      setDisable(false);
    })

  }

  const getCategoryData = (e) => {

    const myurl = "https://events.teambuildingawards.com:3000/api/admin/list-category-filtered";
    var bodyFormData = new URLSearchParams();
    bodyFormData.append('category', 'refresh');
    bodyFormData.append('master_category_id', e);
    axios({
      method: "post",
      url: myurl,
      data: bodyFormData,
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    }).then((response) => {
      //console.log("AF",response?.data?.data);
      setCategories(response?.data?.data);

    }).catch((error) => {
      console.log("Errors", error);
      setDisable(false);
    })

  }

  function setDateFormat(e) {
    var d = new Date(e);
    return (
      ('0' + d.getDate()).slice(-2) +
      '-' +
      ('0' + (d.getMonth() + 1)).slice(-2) +
      '-' +
      d.getFullYear() +
      ' ' +
      tConvert(
        ('0' + d.getHours()).slice(-2) + ':' + ('0' + d.getMinutes()).slice(-2)
      )
    );
  }
  function tConvert(time) {
    // Check correct time format and split into components
    time = time
      .toString()
      .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) {
      // If time format correct
      time = time.slice(1); // Remove full string match value
      time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join(''); // return adjusted time or original string
  }

  const commentsData = useMemo(() => {
    let computedComments = events;

    if (search) {
      computedComments = computedComments.filter(
        (events) =>
          events.category_name.toLowerCase().includes(search.toLowerCase()) ||
          events.host_name.toLowerCase().includes(search.toLowerCase()) ||
          events.title.toLowerCase().includes(search.toLowerCase()) ||
          events.phone_number.toLowerCase().includes(search.toLowerCase()) ||
          events.no_of_guests.toLowerCase().includes(search.toLowerCase()) ||
          events.event_duration.toLowerCase().includes(search.toLowerCase())
      );
    }
    setTotalItems(computedComments.length);

    //Sorting comments
    if (sorting.field) {
      const reversed = sorting.order === 'asc' ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * limit,
      (currentPage - 1) * limit + limit
    );
  }, [currentPage, search, sorting, events, limit]);


  const [filterData, setFilterData] = useState({
    price: '',
    duration: '',
    group: '',
    master_category_id: '',
    category: '',
  });

  const handleInput = (e) => {
    ;
    const basicData = { ...filterData };
    basicData[e.target.name] = e.target.value;

    if (e.target.name == 'master_category_id') {
      getCategoryData(e.target.value);
    }

    setFilterData(basicData);
  }

  const handleSearch = (e) => {
    setDisable(true);
    getEvents();
  }

  const handleReset = () => {
    window.location.reload();
  }


  function renderEventPrice(eventPrice) {
    try {
      const eventPriceArray = JSON.parse(eventPrice?.event_duration);
      if (Array.isArray(eventPriceArray)) {
        return eventPriceArray.map(item => (
          <span key={item.price}>
            {item.price} $,
          </span>
        ));
      } else if (typeof eventPrice === 'string') {
        return <span>{JSON.parse(eventPrice?.price_per_guest)} $</span>;
      } else {
        return <span>{JSON.parse(eventPrice?.price_per_guest)} $</span>;
      }
    } catch (error) {
      console.error('Error parsing event price:', error);
      return <span>Unknown</span>;
    }
  }
  function renderEventDuration(eventDuration) {
    try {
      const eventDurationArray = JSON.parse(eventDuration);
      if (Array.isArray(eventDurationArray)) {
        return eventDurationArray.map(item => (
          <span key={item.duration}>
            {item.duration} Minutes,
          </span>
        ));
      } else if (typeof eventDuration === 'string') {
        return <span>{eventDuration} Minutes</span>;
      } else {
        return <span>Unknown</span>;
      }
    } catch (error) {
      console.error('Error parsing event duration:', error);
      return <span>Unknown</span>;
    }
  }
  return (
    <>
      <Loader />

      <div
        id="page-container"
        className="fade page-sidebar-fixed page-header-fixed"
      >
        <Menu />

        <div id="content" className="content">
          <ol className="breadcrumb float-xl-right">
            <li className="breadcrumb-item">
              <NavLink to="/admin/dashboard">
                <span className="basePath">Dashboard</span>
              </NavLink>
            </li>
            <li className="breadcrumb-item active currentPath">Events</li>
          </ol>
          <h1 className="page-header">Events</h1>


          <div
            style={{
              backgroundColor: 'white',
              padding: '20px',
              borderRadius: '20px',
            }}
          >
            <div className="row w-100">
              <div className="col-12 text-center">
                <h6 style={{ textAlign: "left" }}>Filter Events:</h6>
                <div className="row">
                  <div className="col-md-3 form-group">
                    <select name="price" id="price" className="form-control" onChange={handleInput} value={filterData.price} >
                      <option value="">Select Price</option>
                      <option value="0-50">$0 - $50</option>
                      <option value="50-100">$50 - $100</option>
                      <option value="100-150">$100 - $150</option>
                      <option value="150-200">$150 - $200</option>
                      <option value="200-250">$200 - $250</option>
                      <option value="250">$250 Above</option>
                    </select>
                  </div>

                  <div className="col-md-3 form-group">
                    <select name="duration" id="duration" className="form-control" onChange={handleInput} value={filterData.duration} >
                      <option value="">Select Event Duration</option>
                      <option value="60">60 minutes</option>
                      <option value="90">90 minutes</option>
                      <option value="120">120 minutes</option>
                    </select>
                  </div>

                  <div className="col-md-3 form-group">
                    <select name="master_category_id" id="master_category_id" className="form-control" onChange={handleInput} value={filterData.master_category_id} >
                      <option value="">Select Main Category</option>
                      {masterCategories.length > 0 ?
                        masterCategories.map(row =>
                          <option value={row.master_category_id}>{row.name}</option>
                        )
                        : ''
                      }
                    </select>
                  </div>

                  <div className="col-md-3 form-group">
                    <select name="category" id="category" className="form-control" onChange={handleInput} value={filterData.category} >
                      <option value="">Select Sub Category</option>
                      {categories.length > 0 ?
                        categories.map(row =>
                          <option value={row.category_id}>{row.name}</option>
                        )
                        : ''
                      }
                    </select>
                  </div>
                </div>

                <div className='row'>

                  <div className="col-md-3 form-group">
                    <select name="group" id="group" className="form-control" onChange={handleInput} value={filterData.group} >
                      <option value="">Select Group Size</option>
                      <option value="0-10">0 - 10</option>
                      <option value="10-25">10 - 25</option>
                      <option value="25-50">25 - 50</option>
                      <option value="50-75">50 - 75</option>
                      <option value="75-100">75 - 100</option>
                      <option value="100">100 Above</option>
                    </select>
                  </div>

                  <div className="col-md-2 form-group">
                    <DatePicker className="form-control" placeholderText="Start Created Date" selected={startDate} onChange={(date) => setStartDate(date)} maxDate={new Date()} dateFormat="MMMM dd,yyyy" /> {/* MMMM dd,yyyy   yyyy-MM-dd */}
                  </div>

                  <div className="col-md-2 form-group">
                    <DatePicker className="form-control" placeholderText="End Created Date" selected={endDate} onChange={(date) => setEndDate(date)} maxDate={new Date()} dateFormat="MMMM dd,yyyy" />
                  </div>

                  <div className="col-md-3" style={{ textAlign: "left" }}>
                    <button type='button' className='btn btn-md btn-primary m-t-2' style={{ cursor: "pointer", backgroundColor: '#f0c454' }} disabled={disable} onClick={handleSearch} >{disable ? 'Processing...' : 'Search'}</button>
                    <button type='button' className='btn btn-md btn-primary m-t-2' style={{ cursor: "pointer", backgroundColor: '#f0c454', marginLeft: "10px" }} onClick={handleReset} >Reset</button>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <br />

          <div
            style={{
              backgroundColor: 'white',
              padding: '20px',
              borderRadius: '20px',
            }}
          >
            <div className="row w-100">
              <div className="mb-3 col-12 text-center">
                <div className="row">
                  <div className="col-xl-6 col-lg-6 col-sm-6 col-12 mb-3">
                    <div className="ml-0">
                      <div className="d-flex">
                        <h5 className="mt-2 mr-1">Search: </h5>
                        <Search
                          onSearch={(value) => {
                            setSearch(value);
                            setCurrentPage(1);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-sm-6 col-12 d-flex justify-content-end mb-3">
                    <div
                      style={{
                        color: 'black',
                        fontSize: '12px',
                        fontWeight: '300',
                        paddingTop: '0px',
                        paddingBottom: '0px',
                      }}
                      className="align-self-center"
                    >
                      <b>Rows per page :&nbsp;</b>
                    </div>
                    <div className="align-self-center">
                      <Dropdown>
                        <Dropdown.Toggle
                          variant="none"
                          id="dropdown-basic"
                          style={{
                            cursor: 'auto',
                            backgroundColor: 'white',
                            borderColor: '#d5dbe0',
                            paddingBottom: '3px',
                            paddingTop: '3px',
                          }}
                        >
                          {limit}&nbsp;<i class="fa fa-caret-down"></i>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          {limit !== 10 ? (
                            <>
                              <Dropdown.Item
                                onClick={() => {
                                  setlimit(10);
                                }}
                              >
                                10
                              </Dropdown.Item>
                            </>
                          ) : null}

                          {limit !== 20 ? (
                            <>
                              <Dropdown.Item
                                onClick={() => {
                                  setlimit(20);
                                }}
                              >
                                20
                              </Dropdown.Item>
                            </>
                          ) : null}

                          {limit !== 30 ? (
                            <>
                              <Dropdown.Item
                                onClick={() => {
                                  setlimit(30);
                                }}
                              >
                                30
                              </Dropdown.Item>
                            </>
                          ) : null}

                          {limit !== 50 ? (
                            <>
                              <Dropdown.Item
                                onClick={() => {
                                  setlimit(50);
                                }}
                              >
                                50
                              </Dropdown.Item>
                            </>
                          ) : null}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                </div>

                <div className="row ">
                  <div className="col-12">
                    <div className="table-responsive">
                      <Table striped bordered hover>
                        <thead>
                          <TableHeader
                            headers={Header}
                            onSorting={(field, order) =>
                              setSorting({ field, order })
                            }
                          />
                        </thead>
                        <tbody>
                          {commentsData.map((e, i) => (
                            <tr>
                              <td>{e.sr_no}</td>
                              <td>
                                {e?.image.match('undefined') != 'undefined' ? (
                                  <img
                                    src={e.image}
                                    width="70px"
                                    height="60px"
                                    alt="customer"
                                  />
                                ) : (
                                  <img
                                    src="./assets/img/icon/customer.png"
                                    width="50px"
                                    height="50px"
                                    alt="customer"
                                  />
                                )}
                              </td>
                              <td>{e.master_category_name} - {e.category_name}</td>
                              <td>{e.host_name}</td>
                              <td>{e.title}</td>
                              <td>{e.phone_number}</td>
                              <td>{e.no_of_guests}</td>
                              <td>{renderEventPrice(e)}</td>
                              <td>{renderEventDuration(e.event_duration)}</td>
                              {/* <td>{e.start_date+' '+e.start_time}</td> */}
                              <td>{e.createdAt}</td>
                              <td> <i
                                className="fa fa-eye"
                                style={{ cursor: 'pointer', marginRight: '12px' }}
                                onClick={() => { history.push({ pathname: '/admin/review-details', state: { eventsData: e, }, }) }} ></i></td>
                              <td>
                                {
                                  e.status == 1 ?
                                    <label className="label label-success">Active</label>
                                    :
                                    <label className="label label-danger">Inactive</label>
                                }
                              </td>
                              <td>
                                <i
                                  className="fa fa-eye"
                                  style={{ cursor: 'pointer', marginRight: '12px' }}
                                  onClick={() => {
                                    history.push({
                                      pathname: '/admin/event-details',
                                      state: {
                                        eventsData: e,
                                      },
                                    });
                                  }}
                                ></i>

                                <i
                                  className="fa fa-users"
                                  style={{ cursor: 'pointer', marginRight: '12px' }}
                                  onClick={() => {
                                    history.push({
                                      pathname: '/admin/event-guests',
                                      state: {
                                        eventsData: e,
                                      },
                                    });
                                  }}
                                ></i>

                                <div class="custom-control custom-switch custom-switch-sm ac-inac-switch">
                                  <input type="checkbox" class="custom-control-input" id={e.event_id} data-id={e.event_id} checked={e.status == 1 ? 'checked' : ''} onClick={() => handleStatusEvent(e.event_id, e.status)} />
                                  <label class="custom-control-label" for={e.event_id}></label>
                                </div>

                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>
                <div
                  className="mt-2 d-flex justify-content-sm-center justify-content-xs-center justify-content-lg-end"
                  style={{
                    overflowX: 'auto',
                  }}
                >
                  <Pagination
                    total={totalItems}
                    itemsPerPage={limit}
                    currentPage={currentPage}
                    onPageChange={(page) => setCurrentPage(page)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};

export default Events;
