import React from 'react'

function Footer() {
    return (
        <>
            <a href="javascript:;" className="btn btn-icon btn-circle btn-success btn-scroll-to-top fade" data-click="scroll-top"><i className="fa fa-angle-up"></i></a>
        </>
    )
}

export default Footer
