import React, { useState } from 'react'
import { NavLink, useHistory } from 'react-router-dom'
import { toast } from 'react-toastify';

function Menu() {
   const history = useHistory();
   if (localStorage.getItem("TBA_Admin_ID") == null) {
      //toast.error("Please login first...!");
      history.push(`/team-building-awards-admin`);
   }

   const Logout = () => {
      localStorage.removeItem('TBA_Admin_ID');
      //toast.success("Logout Successfully...!");
      history.push(`/team-building-awards-admin`);
   }

   
   var dashboardClass = window.location.pathname == '/admin/dashboard' ? "active" : "";
   var bannersClass = window.location.pathname == '/admin/banners' ? "active" : "";

   var addBannersClass = window.location.pathname == '/admin/add-banner' ? "active" : "";
   if(addBannersClass=='active'){
      bannersClass = 'active';
   }

   var partnersClass = window.location.pathname == '/admin/partners' ? "active" : "";

   var addPartnersClass = window.location.pathname == '/admin/add-partner' ? "active" : "";
   if(addPartnersClass=='active'){
      partnersClass = 'active';
   }

   var earningClass = window.location.pathname == '/admin/earning' ? "active" : "";

   var cmsClass = window.location.pathname == '/admin/cms-management' ? "active" : "";
   var settingsClass = window.location.pathname == '/admin/settings' ? "active" : "";
   var categoriesClass = window.location.pathname == '/admin/categories' ? "active" : "";
   var addcategoriesClass = window.location.pathname == '/admin/add-category' ? "active" : "";
   
   var editcategoriesClass = window.location.pathname == '/admin/edit-category' ? "active" : "";
   if(addcategoriesClass=='active'){
      categoriesClass = 'active';
   }
   if(editcategoriesClass=='active'){
      categoriesClass = 'active';
   }

   var masterCategoriesClass = window.location.pathname == '/admin/master-categories' ? "active" : "";
   var addmasterCategoriesClass = window.location.pathname == '/admin/add-master-category' ? "active" : "";
   
   var editmasterCategoriesClass = window.location.pathname == '/admin/edit-master-category' ? "active" : "";
   if(addmasterCategoriesClass=='active'){
      masterCategoriesClass = 'active';
   }
   if(editmasterCategoriesClass=='active'){
      masterCategoriesClass = 'active';
   }

   var faqClass = window.location.pathname == '/admin/faq' ? "active" : "";
   var addfaqClass = window.location.pathname == '/admin/add-faq' ? "active" : "";
   
   var editfaqClass = window.location.pathname == '/admin/edit-faq' ? "active" : "";
   if(addfaqClass=='active'){
      faqClass = 'active';
   }
   if(editfaqClass=='active'){
      faqClass = 'active';
   }

   var eventOrganiserClass = window.location.pathname == '/admin/event-organiser-hosts' ? "active" : "";
   var eventOrganiserEditClass = window.location.pathname == '/admin/edit-event-organiser-hosts' ? "active" : "";
   if(eventOrganiserEditClass=='active'){
      eventOrganiserClass = 'active';
   }

   var eventsClass = window.location.pathname == '/admin/events' ? "active" : "";
  
   var eventsDetaqilsClass = window.location.pathname == '/admin/event-details' ? "active" : "";
   var eventsGuestsClass = window.location.pathname == '/admin/event-guests' ? "active" : "";
   if(eventsDetaqilsClass=='active'){
      eventsClass = 'active';
   }
   if(eventsGuestsClass=='active'){
      eventsClass = 'active';
   }

   var eventsMagicShow = window.location.pathname == '/admin/eventsmagicshow' ? "active" : "";
   if(eventsMagicShow=='active'){
      eventsMagicShow = 'active';
   }

   

   var eventsQuestionsClass = window.location.pathname == '/admin/event-questions' ? "active" : "";
   
   var contactsClass = window.location.pathname == '/admin/contacts' ? "active" : "";
   var contactsDetailsClass = window.location.pathname == '/admin/contact-details' ? "active" : "";
   if(contactsDetailsClass=='active'){
      contactsClass = 'active';
   }

   var contactsgiftClass = window.location.pathname == '/admin/gift-contacts' ? "active" : "";
   var contactsDetailsClass = window.location.pathname == '/admin/contact-gift-details' ? "active" : "";
   if(contactsDetailsClass=='active'){
      contactsgiftClass = 'active';
   }

    var guestsClass = window.location.pathname == '/admin/guests' ? "active" : "";

    var articipatedGuestsClass = window.location.pathname == '/admin/participated-guests' ? "active" : "";
    if(articipatedGuestsClass=='active'){
      guestsClass = 'active';
   }
  
   const styles = {
      backgroundColor: '#f0c454'
   }
   return (
      <>
         <div id="header" className="header navbar-default">
            <div className="navbar-header">
               <NavLink to="/admin/dashboard" className="navbar-brand"> {/*<img src='favicon.png' alt='logo' style={{ marginRight: "10px" }} />*/} <img src="/assets/images/logo-dark.png" alt="logo-dark" class="img-fluid" /></NavLink>
               <button type="button" className="navbar-toggle" data-click="sidebar-toggled">
                  <span className="icon-bar"></span>
                  <span className="icon-bar"></span>
                  <span className="icon-bar"></span>
               </button>
            </div>
            <ul className="navbar-nav navbar-right">
               <li className="dropdown navbar-user">
                  <a href="#" className="dropdown-toggle" data-toggle="dropdown">
                     <img src={ localStorage.getItem("TBA_Admin_Image") ? localStorage.getItem("TBA_Admin_Image") : 'assets/img/default-user.png' } alt="" />
                     <span className="d-none d-md-inline">{ localStorage.getItem("TBA_Admin_Name") }</span> <b className="caret"></b>
                  </a>
                  <div className="dropdown-menu dropdown-menu-right">
                      <NavLink to="/admin/admin-profile" className="dropdown-item">Edit Profile</NavLink>
                     <a onClick={Logout} className="dropdown-item">Log Out</a>
                  </div>
               </li>
            </ul>
         </div>
         <div id="sidebar" className="sidebar">
            <div data-scrollbar="true" data-height="100%">

               <ul className="nav">
                  <li className="nav-header">Navigation</li>

                  <li className={dashboardClass}>
                     <NavLink to="/admin/dashboard" >
                        <i className="fa fa-th-large"></i>
                        <span>Dashboard</span>
                     </NavLink>
                  </li>

                  <li className={eventOrganiserClass}>
                  <NavLink to="/admin/event-organiser-hosts" >
                        <i className="fa fa-th-large"></i>
                        <span>Event Organiser - Hosts</span>
                     </NavLink>
                  </li>

                  <li className={eventsClass}>
                  <NavLink to="/admin/events" >
                        <i className="fa fa-calendar"></i>
                        <span>Events</span>
                        </NavLink>
                  </li>

                  <li className={eventsMagicShow}>
                  <NavLink to="/admin/eventsmagicshow" >
                        <i className="fa fa-calendar"></i>
                        <span>Magic Show</span>
                        </NavLink>
                  </li>

                  <li className={eventsQuestionsClass}>
                  <NavLink to="/admin/event-questions" >
                        <i className="fa fa-question"></i>
                        <span>Event Questions</span>
                        </NavLink>
                  </li>

                  <li className={guestsClass}>
                  <NavLink to="/admin/guests" >
                        <i className="fa fa-users"></i>
                        <span>Guests (Bookings)</span>
                        </NavLink>
                  </li>

                  <li className={contactsClass}>
                  <NavLink to="/admin/contacts" >
                        <i className="fa fa-address-book"></i>
                        <span>Contacts</span>
                        </NavLink>
                  </li>

                 {/* <li className={contactsgiftClass}>
                  <NavLink to="/admin/gift-contacts" >
                        <i className="fa fa-address-book"></i>
                        <span>Gift Contacts</span>
                        </NavLink>
                  </li>*/}

                  <li className={bannersClass}>
                     <NavLink to="/admin/banners" >
                        <i className="fa fa-image"></i>
                        <span>Banners</span>
                     </NavLink>
                  </li>

                  <li className={partnersClass}>
                     <NavLink to="/admin/partners" >
                        <i className="fa fa-image"></i>
                        <span>Partners</span>
                     </NavLink>
                  </li>

                  <li className={masterCategoriesClass}>
                     <NavLink to="/admin/master-categories" >
                        <i className="fa fa-list"></i>
                        <span>Master Categories</span>
                     </NavLink>
                  </li>

                  <li className={categoriesClass}>
                     <NavLink to="/admin/categories" >
                        <i className="fa fa-list"></i>
                        <span>Sub Categories</span>
                     </NavLink>
                  </li>

                  <li className={earningClass}>
                     <NavLink to="/admin/earning" >
                        <i className="fa fa-dollar-sign"></i>
                        <span>Earning</span>
                     </NavLink>
                  </li>

                  <li className={faqClass}>
                     <NavLink to="/admin/faq" >
                        <i className="fa fa-question-circle"></i>
                        <span>FAQ</span>
                     </NavLink>
                  </li>

                  <li className={cmsClass}>
                     <NavLink to="/admin/cms-management" >
                        <i className="fa fa-file"></i>
                        <span>CMS Managentnt</span>
                     </NavLink>
                  </li>

                  <li className={settingsClass}>
                     <NavLink to="/admin/settings" >
                        <i className="fa fa-cog"></i>
                        <span>Settings</span>
                     </NavLink>
                  </li>

                  {/* <li className={productsClass}>
                     <NavLink to="/admin/products" >
                        <i className="fa fa-gift"></i>
                        <span>Products</span>
                     </NavLink>
                  </li>

                  <li className={categoriesClass}>
                     <NavLink to='/admin/categories'>
                        <i className="fa fa-shopping-basket"></i>
                        <span>Categories</span>
                     </NavLink>
                  </li>

                  <li className={eCardCategoryClass}>
                     <NavLink to='/admin/e-card-category'>
                        <i className="fa fa-credit-card"></i>
                        <span>E-Card Category</span>
                     </NavLink>
                  </li>

                  <li className={eCardManagementClass}>
                     <NavLink to='/admin/e-card-management'>
                        <i className="fa fa-credit-card"></i>
                        <span>E-Card Management</span>
                     </NavLink>
                  </li>

                  <li className={sendersClass}>
                     <a >
                        <i className="fa fa-user"></i>
                        <span>Senders</span>
                     </a>
                  </li>

                  <li className={recipientsClass}>
                     <a>
                        <i className="fa fa-users"></i>
                        <span>Recipients</span>
                     </a>
                  </li>

                  <li>
                     <a>
                        <i className="fa fa-gifts"></i>
                        <span>Gifts Sent</span>
                     </a>
                  </li>*/}

                  {/* <li className={restaurantsClass}>
                     <NavLink to="/admin/restaurants">
                        <i className="fa fa-building"></i>
                        <span>Restaurants</span>
                     </NavLink>
                  </li> */}

                  {/*<li className={customersClass}>
                      <NavLink to="/admin/customers">
                     <i className="fa fa-users"></i>
                     <span>Customers</span>
                     </NavLink> 
                     <a href="javascript:(0);">
                        <i className="fa fa-users"></i>
                        <span>Customers</span>
                     </a>
                  </li>*/}

               </ul>
            </div>
         </div>

         <div className="sidebar-bg"></div>

      </>
   )
}

export default Menu
