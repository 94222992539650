import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useHistory, Link } from 'react-router-dom';
import axios from 'axios';

function ForgotPassword() {
    const history = useHistory();

    if (localStorage.getItem("TBA_Admin_ID") != null) {
        toast.error("Already Logined...!");
        history.push(`/admin/dashboard`);
     }

    const [emailInfo, setEmailInfo] = useState({
        email: '',
    });
    const [errors, setErrors] = useState({});
    const [disable, setDisable] = useState(false);

    const InputEvent = (e) => {
        const newEmailInfo = { ...emailInfo };
        newEmailInfo[e.target.name] = e.target.value;
        setEmailInfo(newEmailInfo);
    }

    const submitHandler = (e) => {
        e.preventDefault();
        if (validate()) {
            setDisable(true);
            const { email, password } = emailInfo;
            const myurl = "https://events.teambuildingawards.com:3000/api/admin/check-email";
            var bodyFormData = new URLSearchParams();
            bodyFormData.append('email', email);
            axios({
                method: "post",
                url: myurl,
                data: bodyFormData,
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            }).then((response) => {
                console.log(response);
                if (response.data.success == true) {
                    toast.success(response.data.message);
                    history.push(`/team-building-awards-admin`);
                } else {
                    setDisable(false);
                    toast.error(response.data.message);
                }
            })
        }
    }

    const validate = () => {
        let input = emailInfo;

        let errors = {};
        let isValid = true;

        if (!input["email"]) {
            isValid = false;
            errors["email_err"] = "Please enter email";
        }

        setErrors(errors);
        return isValid;
    }

    useEffect(() => {
        document.getElementById('page-loader').style.display = 'none';

        var element = document.getElementById("page-container");
        element.classList.add("show");

    }, []);

    return (
        <>
            <div id="page-loader" className="fade show">
                <span className="spinner"></span>
            </div>

            <div className="login-cover">
                <div className="login-cover-image" style={{ backgroundImage: "url(/assets/img/login-bg/login-bg-17.jpg)" }} data-id="login-cover-image"></div>
                <div className="login-cover-bg"></div>
            </div>

            <div id="page-container" className="fade">
                <div className="login login-v2" data-pageload-addclassName="animated fadeIn">
                    <div className="login-header">
                        <div className="brand">
                            <span className="logo" style={{ backgroundColor: '#f0c454' }}></span> <b>Team Building Awards</b> Admin
                            <small>Forgot Password for admin panel</small>
                        </div>
                        <div className="icon">
                            <i className="fa fa-lock"></i>
                        </div>
                    </div>

                    <div className="login-content">
                        <form onSubmit={(e) => submitHandler(e)} >
                            <div className="form-group m-b-20">
                                <input type="text" className="form-control form-control-lg" placeholder="Email Address" name="email" onChange={InputEvent} />
                                <div className="text-danger">{errors.email_err}</div>
                            </div>

                            <div className="login-buttons">
                                <button type="submit" style={{ backgroundColor: '#f0c454' }} class="btn btn-success btn-block btn-lg" disabled={disable}>{disable ? 'Processing...' : 'Send Email'}</button>
                            </div>

                            <p style={{ marginLeft: "130px", paddingTop: "10px" }}>Known with Credentials then , <Link to='/admin/team-building-awards-admin' style={{ textDecoration: "underline" }}><b>Login</b></Link></p>
                        </form>
                    </div>
                </div>
            </div>

        </>
    )
}

export default ForgotPassword