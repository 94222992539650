import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import Loader from './include/Loader';
import Menu from './include/Menu';
import Footer from './include/Footer';
import { NavLink } from 'react-router-dom';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import validator from 'validator'

const AddBanner = () => {
    useEffect(() => {
        document.getElementById('page-loader').style.display = 'none';

        var element = document.getElementById("page-container");
        element.classList.add("show");
    }, []);


    const [{ alt, src }, setImg] = useState({
        src: '/assets/img/no-image.png',
        alt: '',
      });
    const [addBanner, setAddBanner] = useState(false);
    const [bannerName, setBannerName] = useState({});
    const [disable, setDisable] = useState(false);
    const [errors, setErrors] = useState({});

    const [url, setUrl] = useState('');
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');


    const history = useHistory();
    const handleUrl = (e) => {
        setUrl(e.target.value);
    }

    const handleTitle = (e) => {
      setTitle(e.target.value);
    }

    const handleDescription = (e) => {
      setDescription(e.target.value);
    }

    const handleImg = (e) => {

      let img = new Image()
            img.src = window.URL.createObjectURL(e?.target?.files[0])
            img.onload = () => {
         
            if(img.width == 1240 && img.height <= 550){
      
        if (e.target.files[0]) {
          setImg({
            src: URL.createObjectURL(e.target.files[0]),
            alt: e.target.files[0].name,
          });
          setAddBanner(true);
        }
        setDisable(true);
        setAddBanner(true);
        console.log('PHOTO===>', e?.target?.files[0]);
    
        const myurl = `https://events.teambuildingawards.com:3000/api/upload-img`;
        var bodyFormData = new FormData();
        bodyFormData.append('auth_code', 'Event#Cust$&$Resto#MD');
        bodyFormData.append('image', e?.target?.files[0]);
        //alert(bodyFormData);
        axios({
          method: 'post',
          url: myurl,
          data: bodyFormData,
        })
          .then((result) => {
            console.log('Success:=====', result);
            setBannerName(result?.data?.data?.filepath_url);
            setDisable(false);
          })
          .catch((error) => {
            console.error('Error:', error);
          });

        }else{
          toast.error("Please Upload size 1240 * 550");
      }
    }

      };

    const handleSubmitBanner = (e) => {
        e.preventDefault();
        if (validate()) {
            setDisable(true);
            const myurl = "https://events.teambuildingawards.com:3000/api/admin/add-banner";
            var bodyFormData = new URLSearchParams();
            bodyFormData.append('image', bannerName);
            bodyFormData.append('url', url);
            bodyFormData.append('title', title);
            bodyFormData.append('description', description);
            axios({
                method: "post",
                url: myurl,
                data: bodyFormData,
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            }).then((response) => {
                console.log(response);
                if (response.data.success == true) {
                    setUrl('');
                    toast.success(response.data.message);
                    history.push('/admin/banners')
                }
            }).catch((error) => {
                console.log("Errors", error);
                setDisable(false);
            })
        }
    }

    const validate = () => {
        let input = url;
    
        let errors = {};
        let isValid = true;
    
        
        if (!validator.isURL(input)) {
            isValid = false;
          errors['url_err'] = 'Please enter valid URL';
          }
        
          if (!input) {
            isValid = false;
            errors['url_err'] = 'Please enter URL';
          }

        if (addBanner === false) {
          isValid = false;
          errors['img_err'] = 'Please select image';
        }

        if (!title) {
          isValid = false;
          errors['title_err'] = 'Please enter title';
        }

        if (!description) {
          isValid = false;
          errors['description_err'] = 'Please enter description';
        }
    
        setErrors(errors);
        return isValid;
      };


    return (
        <>
            <Loader />
            <div id="page-container" className="fade page-sidebar-fixed page-header-fixed">
                <Menu />
                <div id="content" className="content">
                    <ol className="breadcrumb float-xl-right">
                        <li className="breadcrumb-item"><NavLink to="/admin/dashboard">Dashboard</NavLink></li>
                        <li className="breadcrumb-item"><NavLink to="/admin/banners">Banners</NavLink></li>
                        <li className="breadcrumb-item active">Add Banner</li>
                    </ol>
                    <h1 className="page-header">Add Banner  </h1>
                    <div className="panel panel-inverse" data-sortable-id="form-stuff-10">
                        <div className="panel-heading ui-sortable-handle">
                            <h4 className="panel-title">Add Banner</h4>
                            <NavLink to="/admin/banners" className="btn btn-sm btn-default pull-right">Back</NavLink>
                        </div>
                        <div className="panel-body">
                        <form
                  onSubmit={(e) => handleSubmitBanner(e)}
                  className="profileForm"
                >
                                <fieldset>
                                    <div className='row'>

                                    <div className="col-md-6 form-group">
                                    <img
                        src={src}
                        className="form-img__img-preview ml-2"
                        style={{
                          width: '160px',
                          height: '100px',
                          borderRadius: '10px',
                        }}
                        alt="Profile_Picture"
                      />
                      <input
                        type="file"
                        className="form-control ml-0"
                        id="exampleInputImage"
                        onChange={handleImg}
                        />
                        <span style={{color:'red'}}>(Size: 1240 * 550)</span>
                      <div className="text-danger">{errors.img_err}</div>
                        </div>
                        </div>


                        <div className='row'>
                                        <div className="col-md-6 form-group">
                                            <label htmlFor="exampleInputCity">Title:</label>
                                            <input type='text' className='form-control' nam='title' onChange={handleTitle} value={title}></input>
                                            <div className="text-danger">{errors.title_err}</div>
                                        </div>
                                        </div>

                                        <div className='row'>
                                        <div className="col-md-6 form-group">
                                            <label htmlFor="exampleInputCity">Description:</label>
                                            <textarea type='text' className='form-control' nam='description' onChange={handleDescription} value={description}></textarea>
                                            <div className="text-danger">{errors.description_err}</div>
                                        </div>
                                        </div>

                        <div className='row'>
                                        <div className="col-md-6 form-group">
                                            <label htmlFor="exampleInputCity">URL:</label>
                                            <input type='text' className='form-control' nam='url' onChange={handleUrl} value={url}></input>
                                            <div className="text-danger">{errors.url_err}</div>
                                        </div>
                                        </div>
                                        <div className='row'>
                                        <div className='col-md-6 form-group'>
                                            <button type='submit' className='btn btn-md btn-primary m-t-30' style={{ fontSize: "15px", backgroundColor: '#f0c454' }} disabled={disable}>{disable ? 'Processing...' : 'Submit'}</button>
                                        </div>
                                    </div>
                                </fieldset>
                            </form>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    )
}

export default AddBanner