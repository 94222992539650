import React, { useState, useEffect, useMemo } from 'react';
import { toast } from 'react-toastify';
import { NavLink, useHistory } from 'react-router-dom';
import Loader from './include/Loader';
import Menu from './include/Menu';
import Footer from './include/Footer';
import axios from 'axios';
import { TableHeader, Pagination, Search } from './Table';
import { Dropdown, Table } from 'react-bootstrap';

import moment from 'moment';
import 'moment-timezone';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const EventsMagicShow = () => {
    
  let history = useHistory();

  const [disable, setDisable] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");


  const [masterCategories, setMasterCategories] = useState({});
  const [categories, setCategories] = useState({});

  const [events, setEvents] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState('');
  const [sorting, setSorting] = useState({ field: '', order: '' });
  const [limit, setlimit] = useState(10);
  const Header = [
    {
      name: 'Sr No.',
      field: 'sr_no',
      sortable: false,
    },
    {
      name: 'Image',
      field: 'image',
      sortable: false,
    },
    // {
    //   name: 'Category Name',
    //   field: 'category_name',
    //   sortable: false,
    // },
    // {
    //   name: 'Host Info',
    //   field: 'host_name',
    //   sortable: false,
    // },
    {
      name: 'Title',
      field: 'title',
      sortable: false,
    },
    // {
    //   name: 'Host Phone No.',
    //   field: 'phone_number',
    //   sortable: false,
    // },
    // {
    //   name: 'No. of Guests',
    //   field: 'no_of_guests',
    //   sortable: false,
    // },
    // {
    //   name: 'Price per Guest',
    //   field: 'price_per_guest',
    //   sortable: false,
    // },
    // {
    //   name: 'Duration',
    //   field: 'event_duration',
    //   sortable: false,
    // },
    // {
    //   name: 'Event Date/Time',
    //   field: 'start_date',
    //   sortable: false,
    // },
    // {
    //   name: 'Created At',
    //   field: 'createdAt',
    //   sortable: false,
    // },
    // {
    //   name: 'Review',
    //   field: 'Review',
    //   sortable: false,
    // },
    {
      name: 'Status',
      field: 'status',
      sortable: false,
    },
    {
      name: 'Action',
      field: 'action',
      sortable: false,
    },
  ];

// Spotlight Acrive
  const handleActivatespotlightEvent = (event_id,status) => {

    if (window.confirm("Do you want to change status ?")) {
      const myurl = "https://events.teambuildingawards.com:3000/api/web/edit-spotlight-events";
      var bodyFormData = new URLSearchParams();
      bodyFormData.append('event_id', event_id);
      bodyFormData.append('spotlight',status);
      axios({
        method: "put",
        url: myurl,
        data: bodyFormData,
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      }).then((response) => {
        console.log(response);
        if (response.data.success == true) {
          getEvents();
          toast.success(response.data.message);
        }
      }).catch((error) => {
        console.log("Errors", error);
      })
    }
  }

  const getEvents = () => {
    const myurl = "https://events.teambuildingawards.com:3000/api/admin/list-event";
    var bodyFormData = new URLSearchParams();
   
    bodyFormData.append('master_category_id', 1);
    bodyFormData.append('category', 20);
   
    axios({
      method: 'post',
      url: myurl,
      data: bodyFormData,
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    })
      .then((response) => {
        setDisable(false);
        console.log(response['data']['data']);
        var indexedData = response['data']['data']?.map((e, i) => {
          e = { ...e };
          e = { ...e, sr_no: i + 1 };
          e = {
            ...e,
            //createdAt: setDateFormat(e.createdAt),
            createdAt: moment.tz(e.createdAt, "America/New_York").format('MMMM D, YYYY, hh:mm A'),
            image: e.image ? e.image : 'N/A',
            category_name: e.category_name ? e.category_name : 'N/A',
            host_name: e.host_name ? e.host_name : 'N/A',
            title: e.title ? e.title : 'N/A',
            phone_number: e.phone_number ? e.phone_number : 'N/A',
            no_of_guests: e.no_of_guests ? e.no_of_guests : 'N/A',
            price_per_guest: e.price_per_guest ? e.price_per_guest : 'N/A',
            event_duration: e.event_duration ? e.event_duration : 'N/A',
            start_date: e.start_date ? e.start_date : 'N/A',

          };
          return e;
        });
        setEvents(indexedData);
      })
      .catch((error) => {
        console.log('Errors', error);
      });
  };

  useEffect(() => {
    getEvents();

    document.getElementById('page-loader').style.display = 'none';

    var element = document.getElementById('page-container');
    element.classList.add('show');
    //getCategoryData();
   
  }, []);


 


 
  const commentsData = useMemo(() => {
    let computedComments = events;

    if (search) {
      computedComments = computedComments.filter(
        (events) =>
          events.category_name.toLowerCase().includes(search.toLowerCase()) ||
          events.host_name.toLowerCase().includes(search.toLowerCase()) ||
          events.title.toLowerCase().includes(search.toLowerCase()) ||
          events.phone_number.toLowerCase().includes(search.toLowerCase()) ||
          events.no_of_guests.toLowerCase().includes(search.toLowerCase()) ||
          events.event_duration.toLowerCase().includes(search.toLowerCase())
      );
    }
    setTotalItems(computedComments.length);

    //Sorting comments
    if (sorting.field) {
      const reversed = sorting.order === 'asc' ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * limit,
      (currentPage - 1) * limit + limit
    );
  }, [currentPage, search, sorting, events, limit]);


  const [filterData, setFilterData] = useState({
    price: '',
    duration: '',
    group: '',
    master_category_id: '',
    category: '',
  });




  function renderEventPrice(eventPrice) {
    try {
      const eventPriceArray = JSON.parse(eventPrice?.event_duration);
      if (Array.isArray(eventPriceArray)) {
        return eventPriceArray.map(item => (
          <span key={item.price}>
            {item.price} $,
          </span>
        ));
      } else if (typeof eventPrice === 'string') {
        return <span>{JSON.parse(eventPrice?.price_per_guest)} $</span>;
      } else {
        return <span>{JSON.parse(eventPrice?.price_per_guest)} $</span>;
      }
    } catch (error) {
      console.error('Error parsing event price:', error);
      return <span>Unknown</span>;
    }
  }
  function renderEventDuration(eventDuration) {
    try {
      const eventDurationArray = JSON.parse(eventDuration);
      if (Array.isArray(eventDurationArray)) {
        return eventDurationArray.map(item => (
          <span key={item.duration}>
            {item.duration} Minutes,
          </span>
        ));
      } else if (typeof eventDuration === 'string') {
        return <span>{eventDuration} Minutes</span>;
      } else {
        return <span>Unknown</span>;
      }
    } catch (error) {
      console.error('Error parsing event duration:', error);
      return <span>Unknown</span>;
    }
  }
  return (
    <>
      <Loader />

      <div
        id="page-container"
        className="fade page-sidebar-fixed page-header-fixed"
      >
        <Menu />

        <div id="content" className="content">
          <ol className="breadcrumb float-xl-right">
            <li className="breadcrumb-item">
              <NavLink to="/admin/dashboard">
                <span className="basePath">Dashboard</span>
              </NavLink>
            </li>
            <li className="breadcrumb-item active currentPath">Events Magic Show</li>
          </ol>
          <h1 className="page-header">Events Magic Show</h1>


          <div
            style={{
              backgroundColor: 'white',
              padding: '20px',
              borderRadius: '20px',
            }}
          >
         
          </div>


          <br />

          <div
            style={{
              backgroundColor: 'white',
              padding: '20px',
              borderRadius: '20px',
            }}
          >
            <div className="row w-100">
              <div className="mb-3 col-12 text-center">
                <div className="row">
                  <div className="col-xl-6 col-lg-6 col-sm-6 col-12 mb-3">
                    <div className="ml-0">
                      <div className="d-flex">
                        <h5 className="mt-2 mr-1">Search: </h5>
                        <Search
                          onSearch={(value) => {
                            setSearch(value);
                            setCurrentPage(1);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-sm-6 col-12 d-flex justify-content-end mb-3">
                    <div
                      style={{
                        color: 'black',
                        fontSize: '12px',
                        fontWeight: '300',
                        paddingTop: '0px',
                        paddingBottom: '0px',
                      }}
                      className="align-self-center"
                    >
                      <b>Rows per page :&nbsp;</b>
                    </div>
                    <div className="align-self-center">
                      <Dropdown>
                        <Dropdown.Toggle
                          variant="none"
                          id="dropdown-basic"
                          style={{
                            cursor: 'auto',
                            backgroundColor: 'white',
                            borderColor: '#d5dbe0',
                            paddingBottom: '3px',
                            paddingTop: '3px',
                          }}
                        >
                          {limit}&nbsp;<i class="fa fa-caret-down"></i>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          {limit !== 10 ? (
                            <>
                              <Dropdown.Item
                                onClick={() => {
                                  setlimit(10);
                                }}
                              >
                                10
                              </Dropdown.Item>
                            </>
                          ) : null}

                          {limit !== 20 ? (
                            <>
                              <Dropdown.Item
                                onClick={() => {
                                  setlimit(20);
                                }}
                              >
                                20
                              </Dropdown.Item>
                            </>
                          ) : null}

                          {limit !== 30 ? (
                            <>
                              <Dropdown.Item
                                onClick={() => {
                                  setlimit(30);
                                }}
                              >
                                30
                              </Dropdown.Item>
                            </>
                          ) : null}

                          {limit !== 50 ? (
                            <>
                              <Dropdown.Item
                                onClick={() => {
                                  setlimit(50);
                                }}
                              >
                                50
                              </Dropdown.Item>
                            </>
                          ) : null}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                </div>

                <div className="row ">
                  <div className="col-12">
                    <div className="table-responsive">
                      <Table striped bordered hover>
                        <thead>
                          <TableHeader
                            headers={Header}
                            onSorting={(field, order) =>
                              setSorting({ field, order })
                            }
                          />
                        </thead>
                        <tbody>
                          {commentsData.map((e, i) => (
                            <tr>
                              <td>{e.sr_no}</td>
                              <td>
                                {e?.image.match('undefined') != 'undefined' ? (
                                  <img
                                    src={e.image}
                                    width="70px"
                                    height="60px"
                                    alt="customer"
                                  />
                                ) : (
                                  <img
                                    src="./assets/img/icon/customer.png"
                                    width="50px"
                                    height="50px"
                                    alt="customer"
                                  />
                                )}
                              </td>
                              {/* <td>{e.master_category_name} - {e.category_name}</td>
                              <td>{e.host_name}</td> */}
                              <td>{e.title}</td>
                              {/* <td>{e.phone_number}</td>
                              <td>{e.no_of_guests}</td>
                              <td>{renderEventPrice(e)}</td>
                              <td>{renderEventDuration(e.event_duration)}</td> */}
                              {/* <td>{e.start_date+' '+e.start_time}</td> */}
                              {/* <td>{e.createdAt}</td> */}
                              {/* <td> <i
                                className="fa fa-eye"
                                style={{ cursor: 'pointer', marginRight: '12px' }}
                                onClick={() => { history.push({ pathname: '/admin/review-details', state: { eventsData: e, }, }) }} ></i></td> */}
                              <td>
                                {
                                  e.spotlight === "1" ?
                                    <label className="label label-success">Active</label>
                                    :
                                    <label className="label label-danger">Inactive</label>
                                }
                              </td>
                              <td>
                              {
                                  e.spotlight === "1" ?
                                    <label className="label label-danger" onClick={() => handleActivatespotlightEvent(e.event_id,0)}>Click to In Active</label>
                                    :
                                    <label className="label label-success" onClick={() => handleActivatespotlightEvent(e.event_id,1)}>Click to Active</label>
                                }
                              {/* <p onClick={() => handleActivatespotlightEvent(e.event_id,1)} >Activate</p>
                              <p onClick={() => handleActivatespotlightEvent(e.event_id,0)} >In Activate</p> */}
                                {/* <i
                                  className="fa fa-eye"
                                  style={{ cursor: 'pointer', marginRight: '12px' }}
                                  onClick={() => {
                                    history.push({
                                      pathname: '/admin/event-details',
                                      state: {
                                        eventsData: e,
                                      },
                                    });
                                  }}
                                ></i> */}

                                {/* <i
                                  className="fa fa-users"
                                  style={{ cursor: 'pointer', marginRight: '12px' }}
                                  onClick={() => {
                                    history.push({
                                      pathname: '/admin/event-guests',
                                      state: {
                                        eventsData: e,
                                      },
                                    });
                                  }}
                                ></i> */}

                                {/* <div class="custom-control custom-switch custom-switch-sm ac-inac-switch">
                                  <input type="checkbox" class="custom-control-input" id={e.event_id} data-id={e.event_id} checked={e.status == 1 ? 'checked' : ''} onClick={() => handleStatusEvent(e.event_id, e.status)} />
                                  <label class="custom-control-label" for={e.event_id}></label>
                                </div> */}

                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>
                <div
                  className="mt-2 d-flex justify-content-sm-center justify-content-xs-center justify-content-lg-end"
                  style={{
                    overflowX: 'auto',
                  }}
                >
                  <Pagination
                    total={totalItems}
                    itemsPerPage={limit}
                    currentPage={currentPage}
                    onPageChange={(page) => setCurrentPage(page)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};

export default EventsMagicShow;
