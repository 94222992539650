import React, { useState, useEffect, useMemo } from 'react';
import { toast } from 'react-toastify';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import Loader from './include/Loader';
import Menu from './include/Menu';
import Footer from './include/Footer';
import axios from 'axios';
import { TableHeader, Pagination, Search } from './Table';
import { Dropdown, Table } from 'react-bootstrap';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import moment from 'moment';
import 'moment-timezone';

const EventGuests = () => {
  let history = useHistory();
  const location = useLocation();
    var record = location.state;

    const [disable, setDisable] = useState(false);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [eventDate, setEventDate] = useState(""); 

  const [guests, setGuests] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState('');
  const [sorting, setSorting] = useState({ field: '', order: '' });
  const [limit, setlimit] = useState(10);
  const Header = [
    {
      name: 'Sr No.',
      field: 'sr_no',
      sortable: false,
    },
    {
      name: 'Event Image',
      field: 'image',
      sortable: false,
    },
    {
      name: 'Event Info',
      field: 'title',
      sortable: false,
    },
    {
      name: 'Event Date',
      field: 'event_date',
      sortable: false,
    },
    {
      name: 'Event Time',
      field: 'event_time',
      sortable: false,
    },
    {
      name: 'Price',
      field: 'price',
      sortable: false,
    },
    {
      name: 'Attendees',
      field: 'event_attendees',
      sortable: false,
    },
    {
      name: 'Total',
      field: 'total',
      sortable: false,
    },
    {
      name: 'Company Name',
      field: 'company_name',
      sortable: false,
    },
    {
      name: 'Created At',
      field: 'createdAt',
      sortable: false,
    },
    {
      name: 'Action',
      field: 'action',
      sortable: false,
    }
  ];


  const getGuests = () => {
    const myurl = "https://events.teambuildingawards.com:3000/api/admin/list-guest";
    var bodyFormData = new URLSearchParams();
    bodyFormData.append('event_id', record.eventsData.event_id);
    bodyFormData.append('startDate',startDate);
    bodyFormData.append('endDate', endDate);
    bodyFormData.append('eventDate', eventDate);

    axios({
      method: 'post',
      url: myurl,
      data: bodyFormData,
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    })
      .then((response) => {
        setDisable(false);
        console.log(response['data']['data']);
        var indexedData = response['data']['data']?.map((e, i) => {
          e = { ...e };
          e = { ...e, sr_no: i + 1 };
          e = {
            ...e,
            //createdAt: setDateFormat(e.createdAt),
            createdAt: moment.tz(e.createdAt,"America/New_York").format('MMMM D, YYYY, hh:mm A'),
            image: e?.event?.image ? e?.event?.image : 'N/A',
            title: e?.event?.title ? e?.event?.title : 'N/A',
            //event_date: e.event_date ? e.event_date : 'N/A',
            event_date: moment(e.event_date).format('MMMM D, YYYY'),
            event_time: e.event_time ? e.event_time : 'N/A',
            price: e.price ? e.price : 'N/A',
            event_attendees: e.event_attendees ? e.event_attendees : 'N/A',
            total: e.total ? e.total : 'N/A',
            company_name: e.company_name ? e.company_name : 'N/A',

          };
          return e;
        });
        setGuests(indexedData);
      })
      .catch((error) => {
        console.log('Errors', error);
      });
  };

  useEffect(() => {
    getGuests();

    document.getElementById('page-loader').style.display = 'none';

    var element = document.getElementById('page-container');
    element.classList.add('show');
  }, []);

  function setDateFormat(e) {
    var d = new Date(e);
    return (
      ('0' + d.getDate()).slice(-2) +
      '-' +
      ('0' + (d.getMonth() + 1)).slice(-2) +
      '-' +
      d.getFullYear() +
      ' ' +
      tConvert(
        ('0' + d.getHours()).slice(-2) + ':' + ('0' + d.getMinutes()).slice(-2)
      )
    );
  }
  function tConvert(time) {
    // Check correct time format and split into components
    time = time
      .toString()
      .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) {
      // If time format correct
      time = time.slice(1); // Remove full string match value
      time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join(''); // return adjusted time or original string
  }

  const commentsData = useMemo(() => {
    let computedComments = guests;

    if (search) {
      // computedComments = computedComments.filter(
      //   (guests) =>
      //     guests?.event_date.toLowerCase().includes(search.toLowerCase()) ||
      //     guests?.event_time.toLowerCase().includes(search.toLowerCase()) ||
      //     guests?.price.toLowerCase().includes(search.toLowerCase()) ||
      //     guests?.event_attendees.toLowerCase().includes(search.toLowerCase()) ||
      //     guests?.total.toLowerCase().includes(search.toLowerCase()) ||
      //     guests?.company_name.toLowerCase().includes(search.toLowerCase())
      // );
    }
    setTotalItems(computedComments.length);

    //Sorting comments
    if (sorting.field) {
      const reversed = sorting.order === 'asc' ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * limit,
      (currentPage - 1) * limit + limit
    );
  }, [currentPage, search, sorting, guests, limit]);

  const handleSearch = (e) => {
    setDisable(true);
    getGuests();
  }

  const handleReset = () => {
    window.location.reload();
  }
  

  return (
    <>
      <Loader />

      <div
        id="page-container"
        className="fade page-sidebar-fixed page-header-fixed"
      >
        <Menu />

        <div id="content" className="content">
          <ol className="breadcrumb float-xl-right">
            <li className="breadcrumb-item">
              <NavLink to="/admin/dashboard">
                <span className="basePath">Dashboard</span>
              </NavLink>
            </li>
            <li className="breadcrumb-item">
              <NavLink to="/admin/events">
                <span className="basePath">Events</span>
              </NavLink>
            </li>
            <li className="breadcrumb-item active currentPath">Event Guests</li>
          </ol>
          <h1 className="page-header">Event Guests - {record.eventsData.title}</h1>

          <div
            style={{
              backgroundColor: 'white',
              padding: '20px',
              borderRadius: '20px',
            }}
          >
            <div className="row w-100">
                <div className="col-12 text-center">
                <h6 style={{textAlign:"left"}}>Filter Event Guests - {record.eventsData.title} :</h6>
                  

                      <div className='row'>

                      <div className="col-md-2 form-group">
                        <DatePicker className="form-control" placeholderText="Select Event Date" selected={eventDate} onChange={(date) => setEventDate(date)} dateFormat="MMMM dd,yyyy" />
                      </div>

                      <div className="col-md-2 form-group">
                        <DatePicker className="form-control" placeholderText="Start Created Date" selected={startDate} onChange={(date) => setStartDate(date)} maxDate={new Date()} dateFormat="MMMM dd,yyyy" />
                      </div>

                      <div className="col-md-2 form-group">
                        <DatePicker className="form-control" placeholderText="End Created Date" selected={endDate} onChange={(date) => setEndDate(date)} maxDate={new Date()} dateFormat="MMMM dd,yyyy" />
                      </div>

                      <div className="col-md-3" style={{ textAlign: "left"}}>
                        <button type='button' className='btn btn-md btn-primary m-t-2' style={{ cursor:"pointer", backgroundColor: '#f0c454' }} disabled={disable} onClick={handleSearch} >{disable ? 'Processing...' : 'Search'}</button>
                        <button type='button' className='btn btn-md btn-primary m-t-2' style={{ cursor:"pointer", backgroundColor: '#f0c454', marginLeft: "10px" }} onClick={handleReset} >Reset</button>
                      </div>
                  </div>
                </div>
              </div>
            </div>


<br/>

          <div
            style={{
              backgroundColor: 'white',
              padding: '20px',
              borderRadius: '20px',
            }}
          >
            <div className="row w-100">
              <div className="mb-3 col-12 text-center">
                <div className="row">
                  <div className="col-xl-6 col-lg-6 col-sm-6 col-12 mb-3">
                    <div className="ml-0">
                      <div className="d-flex">
                        <h5 className="mt-2 mr-1">Search: </h5>
                        <Search
                          onSearch={(value) => {
                            setSearch(value);
                            setCurrentPage(1);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-sm-6 col-12 d-flex justify-content-end mb-3">
                    <div
                      style={{
                        color: 'black',
                        fontSize: '12px',
                        fontWeight: '300',
                        paddingTop: '0px',
                        paddingBottom: '0px',
                      }}
                      className="align-self-center"
                    >
                      <b>Rows per page :&nbsp;</b>
                    </div>
                    <div className="align-self-center">
                      <Dropdown>
                        <Dropdown.Toggle
                          variant="none"
                          id="dropdown-basic"
                          style={{
                            cursor: 'auto',
                            backgroundColor: 'white',
                            borderColor: '#d5dbe0',
                            paddingBottom: '3px',
                            paddingTop: '3px',
                          }}
                        >
                          {limit}&nbsp;<i class="fa fa-caret-down"></i>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          {limit !== 10 ? (
                            <>
                              <Dropdown.Item
                                onClick={() => {
                                  setlimit(10);
                                }}
                              >
                                10
                              </Dropdown.Item>
                            </>
                          ) : null}

                          {limit !== 20 ? (
                            <>
                              <Dropdown.Item
                                onClick={() => {
                                  setlimit(20);
                                }}
                              >
                                20
                              </Dropdown.Item>
                            </>
                          ) : null}

                          {limit !== 30 ? (
                            <>
                              <Dropdown.Item
                                onClick={() => {
                                  setlimit(30);
                                }}
                              >
                                30
                              </Dropdown.Item>
                            </>
                          ) : null}

                          {limit !== 50 ? (
                            <>
                              <Dropdown.Item
                                onClick={() => {
                                  setlimit(50);
                                }}
                              >
                                50
                              </Dropdown.Item>
                            </>
                          ) : null}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                </div>

                <div className="row ">
                  <div className="col-12">
                    <div className="table-responsive">
                      <Table striped bordered hover>
                        <thead>
                          <TableHeader
                            headers={Header}
                            onSorting={(field, order) =>
                              setSorting({ field, order })
                            }
                          />
                        </thead>
                        <tbody>
                          {commentsData.map((e, i) => (
                            <tr>
                              <td>{e.sr_no}</td>
                              <td>
                                {e?.image.match('undefined') != 'undefined' ? (
                                  <img
                                    src={e.image}
                                    width="70px"
                                    height="60px"
                                    alt="customer"
                                  />
                                ) : (
                                  <img
                                    src="./assets/img/icon/customer.png"
                                    width="50px"
                                    height="50px"
                                    alt="customer"
                                  />
                                )}
                              </td>
                              <td style={{textAlign:"left"}}>{e.event.title}<br />Duration: <b>{e.event.event_duration}</b></td>
                              <td>{e.event_date}</td>
                              <td>{e.event_time}</td>
                              <td>{e.price}</td>
                              <td>{e.event_attendees}</td>
                              <td>{e.total}</td>
                              <td>{e.company_name}</td>
                              <td>{e.createdAt}</td>
                              <td> 
                              <i
                                  className="fa fa-users"
                                  style={{ cursor: 'pointer', marginRight:'12px' }}
                                  onClick={() => {
                                    history.push({
                                      pathname: '/admin/participated-guests',
                                      state: {
                                        guestsData: e,
                                      },
                                    });
                                  }}
                                ></i>
                                 
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>
                <div
                  className="mt-2 d-flex justify-content-sm-center justify-content-xs-center justify-content-lg-end"
                  style={{
                    overflowX: 'auto',
                  }}
                >
                  <Pagination
                    total={totalItems}
                    itemsPerPage={limit}
                    currentPage={currentPage}
                    onPageChange={(page) => setCurrentPage(page)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};

export default EventGuests;
