import React, { useState, useEffect } from 'react'
import { NavLink, useHistory } from 'react-router-dom'

import { toast } from 'react-toastify';
import axios from 'axios';

function Login() {
    const history = useHistory();

    if (localStorage.getItem("TBA_Admin_ID") != null) {
      //  toast.error("Already Logined...!");
        history.push(`/admin/dashboard`);
     }

    const [loginInfo, setLoginInfo] = useState({
        email: '',
        password: ''
    });
    const [check, setCheck] = useState(false);
    const [errors, setErrors] = useState({});
    const [disable, setDisable] = useState(false);

    const InputEvent = (e) => {

        const newLoginInfo = { ...loginInfo };
        newLoginInfo[e.target.name] = e.target.value;
        setLoginInfo(newLoginInfo);

    }

    const submitHandler = (e) => {
        e.preventDefault();
        if (validate()) {
            setDisable(true);
            const { email, password } = loginInfo;

            const myurl = "https://events.teambuildingawards.com:3000/api/admin/login";
            var bodyFormData = new URLSearchParams();
            bodyFormData.append('email', email);
            bodyFormData.append('password', password);
            axios({
                method: "post",
                url: myurl,
                data: bodyFormData,
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            }).then((response) => {
                console.log(response);
                if (response.data.success == true) {
                    localStorage.setItem('TBA_Admin_ID', response.data.data.id);
                    localStorage.setItem('TBA_Admin_EMAIL', response.data.data.email);
                    localStorage.setItem('TBA_Admin_Name', response.data.data.name);
                    localStorage.setItem('TBA_Admin_Image', "https://events.teambuildingawards.com:3000/uploads/"+response.data.data.image);
                    localStorage.setItem('TBA_Admin_PASS', loginInfo.password);
                    toast.success("Login Successfully...!");
                    if (check === true) {
                        localStorage.setItem('TBA_Remember_Me', 'Enabled')
                    } else {
                        localStorage.setItem('TBA_Remember_Me', 'Disabled')
                    }
                    history.push(`/admin/dashboard`);
                } else {
                    setDisable(false);
                    toast.error("Your Email Id and Password does not match...!");
                }

            }).catch((error) => {
                console.log("Errors", error);
            })


        }
    }

    const validate = () => {
        let input = loginInfo;

        let errors = {};
        let isValid = true;

        if (!input["email"]) {
            isValid = false;
            errors["email_err"] = "Please enter email";
        }
        if (!input["password"]) {
            isValid = false;
            errors["password_err"] = "Please enter password";
        }

        setErrors(errors);
        return isValid;
    }

    const handleRememberMe = (e) => {
        if (e.target.checked) {
            setCheck(true)
        } else {
            setCheck(false)
        }
    }

    useEffect(() => {
        document.getElementById('page-loader').style.display = 'none';

        var element = document.getElementById("page-container");
        element.classList.add("show");
        if (localStorage.getItem('TBA_Remember_Me') === 'Enabled') {
            setCheck(true);
            setLoginInfo({
                email: localStorage.getItem('TBA_Admin_EMAIL'),
                password: localStorage.getItem('TBA_Admin_PASS')
            })
        }
    }, []);

    return (
        <>

            <div id="page-loader" className="fade show">
                <span className="spinner"></span>
            </div>


            <div className="login-cover">
                <div className="login-cover-image" style={{ backgroundImage: "url(assets/img/login-bg/login-bg-17.jpg)" }} data-id="login-cover-image"></div>
                <div className="login-cover-bg"></div>
            </div>


            <div id="page-container" className="fade">

                <div className="login login-v2" data-pageload-addclassName="animated fadeIn">

                    <div className="login-header">
                        <div className="brand">
                            <span className="logo" style={{ backgroundColor: '#f0c454' }}></span> <b>Team Building Awards</b> <span>Admin</span>
                            <small>Login for admin panel</small>
                        </div>
                        <div className="icon">
                            <i className="fa fa-lock"></i>
                        </div>
                    </div>


                    <div className="login-content">
                        <form onSubmit={(e) => submitHandler(e)} >
                            <div className="form-group m-b-20">
                                <input type="text" className="form-control form-control-lg" placeholder="Email Address" name="email" onChange={InputEvent} value={loginInfo.email} />
                                <div className="text-danger">{errors.email_err}</div>
                            </div>
                            <div className="form-group m-b-20">
                                <input type="password" className="form-control form-control-lg" placeholder="Password" name="password" onChange={InputEvent} value={loginInfo.password} />
                                <div className="text-danger">{errors.password_err}</div>
                            </div>
                            <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: "10px" }}>
                                <NavLink to='/admin/forgot-password' style={{ textDecoration: "underline" }}>Forgot Password</NavLink>
                            </div>
                            <div className="form-group m-b-20">
                                <input type='checkbox' checked={check} value={check} onChange={handleRememberMe} /> Remember Me
                            </div>
                            <div className="login-buttons">
                                <button type="submit" style={{ backgroundColor: '#f0c454' }} class="btn btn-success btn-block btn-lg" disabled={disable}>{disable ? 'Processing...' : 'Log In'}</button>
                            </div>
                        </form>
                    </div>


                </div>
            </div>

        </>
    )
}

export default Login