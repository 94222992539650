import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'

import Loader from './include/Loader';
import Menu from './include/Menu';
import Footer from './include/Footer';
import axios from 'axios'

function Dashboard() {

   const [dashboard, setDashboard] = useState({});

   const getDashboard = () => {
      const myurl = "https://events.teambuildingawards.com:3000/api/admin/list-dashboard";
      var bodyFormData = new URLSearchParams();
   
      axios({
        method: 'post',
        url: myurl,
        data: bodyFormData,
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      })
        .then((response) => {

          setDashboard(response['data']['data']);
        })
        .catch((error) => {
          console.log('Errors', error);
        });
    };

    useEffect(() => {
      getDashboard();
      
      document.getElementById('page-loader').style.display = 'none';

      var element = document.getElementById("page-container");
      element.classList.add("show");
   }, []);

   console.log("FQ",dashboard);

   return (
      <>

         <Loader />

         <div id="page-container" className="fade page-sidebar-fixed page-header-fixed">

            <Menu />



            <div id="content" className="content">
               <ol className="breadcrumb float-xl-right">
                  <li className="breadcrumb-item"><a href="javascript:;">Home</a></li>
                  <li className="breadcrumb-item active">Dashboard</li>
               </ol>
               <h1 className="page-header">Dashboard </h1>
               <div className="row">

               <div className="col-xl-3 col-md-6">
                     <div className="widget widget-stats bg-orange">
                        <div className="stats-icon"><i className="fa fa-dollar-sign"></i></div>
                        <div className="stats-info">
                           <h3>Total Earning</h3>
                           <p>{dashboard.earn}</p>
                        </div>
                        <div className="stats-link">
                        <NavLink to="/admin/earning">View Detail <i className="fa fa-arrow-alt-circle-right"></i></NavLink>
                        </div>
                     </div>
                  </div>

                  <div className="col-xl-3 col-md-6">
                     <div className="widget widget-stats bg-blue">
                        <div className="stats-icon"><i className="fa fa-users"></i></div>
                        <div className="stats-info">
                           <h3>Event Organiser - Hosts</h3>
                           <p>{dashboard.hosts}</p>
                        </div>
                        <div className="stats-link">
                           <NavLink to="/admin/event-organiser-hosts">View Detail <i className="fa fa-arrow-alt-circle-right"></i></NavLink>
                        </div>
                     </div>
                  </div>
                  <div className="col-xl-3 col-md-6">
                     <div className="widget widget-stats bg-info">
                        <div className="stats-icon"><i className="fa fa-calendar"></i></div>
                        <div className="stats-info">
                           <h3>Events</h3>
                           <p>{dashboard.events}</p>
                        </div>
                        <div className="stats-link">
                           <NavLink to="/admin/events">View Detail <i className="fa fa-arrow-alt-circle-right"></i></NavLink>
                        </div>
                     </div>
                  </div>
                  <div className="col-xl-3 col-md-6">
                     <div className="widget widget-stats bg-orange">
                        <div className="stats-icon"><i className="fa fa-users"></i></div>
                        <div className="stats-info">
                           <h3>Guests</h3>
                           <p>{dashboard.guest}</p>
                        </div>
                        <div className="stats-link">
                        <NavLink to="/admin/guests">View Detail <i className="fa fa-arrow-alt-circle-right"></i></NavLink>
                        </div>
                     </div>
                  </div>
                  <div className="col-xl-3 col-md-6">
                     <div className="widget widget-stats bg-red">
                        <div className="stats-icon"><i className="fa fa-address-book"></i></div>
                        <div className="stats-info">
                           <h3>Contacts</h3>
                           <p>{dashboard.contacts}</p>
                        </div>
                        <div className="stats-link">
                        <NavLink to="/admin/contacts">View Detail <i className="fa fa-arrow-alt-circle-right"></i></NavLink>
                        </div>
                     </div>
                  </div>
                   
               </div>

            </div>

            <Footer />

         </div>
      </>
   )
}

export default Dashboard
