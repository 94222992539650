import React, { useState, useEffect, useMemo } from 'react';
import { toast } from 'react-toastify';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import Loader from './include/Loader';
import Menu from './include/Menu';
import Footer from './include/Footer';
import axios from 'axios';
import { TableHeader, Pagination, Search } from './Table';
import { Dropdown, Table } from 'react-bootstrap';

const ParticipatedGuests = () => {
  let history = useHistory();

  const location = useLocation();
    var record = location.state;
  const [events, setEvents] = useState(record.guestsData.guests);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState('');
  const [sorting, setSorting] = useState({ field: '', order: '' });
  const [limit, setlimit] = useState(10);
  const Header = [
    {
      name: 'Sr No.',
      field: 'sr_no',
      sortable: false,
    },
    {
      name: 'Name',
      field: 'name',
      sortable: false,
    },
    {
      name: 'Email',
      field: 'email',
      sortable: false,
    },
    {
      name: 'Phone Number',
      field: 'phone_number',
      sortable: false,
    },
    {
      name: 'Address',
      field: 'address',
      sortable: false,
    },
    {
      name: 'Created At',
      field: 'createdAt',
      sortable: false,
    },
  ];


  useEffect(() => {
    document.getElementById('page-loader').style.display = 'none';

    var element = document.getElementById('page-container');
    element.classList.add('show');
  }, []);

  function setDateFormat(e) {
    var d = new Date(e);
    return (
      ('0' + d.getDate()).slice(-2) +
      '-' +
      ('0' + (d.getMonth() + 1)).slice(-2) +
      '-' +
      d.getFullYear() +
      ' ' +
      tConvert(
        ('0' + d.getHours()).slice(-2) + ':' + ('0' + d.getMinutes()).slice(-2)
      )
    );
  }
  function tConvert(time) {
    // Check correct time format and split into components
    time = time
      .toString()
      .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) {
      // If time format correct
      time = time.slice(1); // Remove full string match value
      time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join(''); // return adjusted time or original string
  }

  const commentsData = useMemo(() => {
    let computedComments = events;

    if (search) {
      computedComments = computedComments.filter(
        (events) =>
          events.name.toLowerCase().includes(search.toLowerCase()) ||
          events.last_name.toLowerCase().includes(search.toLowerCase()) ||
          events.email.toLowerCase().includes(search.toLowerCase()) ||
          events.phone_number.toLowerCase().includes(search.toLowerCase()) ||
          events.address.toLowerCase().includes(search.toLowerCase())
      );
    }
    setTotalItems(computedComments.length);

    //Sorting comments
    if (sorting.field) {
      const reversed = sorting.order === 'asc' ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * limit,
      (currentPage - 1) * limit + limit
    );
  }, [currentPage, search, sorting, events, limit]);

  function renderEventDuration(eventDuration) {
    try {
      const eventDurationArray = JSON.parse(eventDuration);
      if (Array.isArray(eventDurationArray)) {
        return eventDurationArray.map(item => (
          <span key={item.duration}>
            {item.duration} Minutes,
          </span>
        ));
      } else if (typeof eventDuration === 'string') {
        return <span>{eventDuration} Minutes</span>;
      } else {
        return <span>Unknown</span>;
      }
    } catch (error) {
      console.error('Error parsing event duration:', error);
      return <span>Unknown</span>;
    }
  }
  return (
    <>
      <Loader />

      <div
        id="page-container"
        className="fade page-sidebar-fixed page-header-fixed"
      >
        <Menu />

        <div id="content" className="content">
          <ol className="breadcrumb float-xl-right">
            <li className="breadcrumb-item">
              <NavLink to="/admin/dashboard">
                <span className="basePath">Dashboard</span>
              </NavLink>
            </li>
            <li className="breadcrumb-item">
              <NavLink to="/admin/guests">
                <span className="basePath">Guests</span>
              </NavLink>
            </li>
            <li className="breadcrumb-item active currentPath">Participated Guests</li>
          </ol>
          <h1 className="page-header">Participated Guests</h1>

          <div
            style={{
              backgroundColor: 'white',
              padding: '20px',
              borderRadius: '20px',
            }}
          >

<div className='row'>
                    <div className="col-md-6">
                        <table className="table nowrap">
                        
                            <tr>
                                <td style={{width:"40%"}}><b>Event Title:</b></td>
                                <td>{record?.guestsData.event?.title}</td>
                            </tr>
                            <tr>
                                <td><b>No. of Guests:</b></td>
                                <td>{record?.guestsData.event?.no_of_guests}</td>
                            </tr>
                            <tr>
                                <td><b>Event Duration:</b></td>
                                <td>{renderEventDuration(record?.guestsData?.event?.event_duration)}</td>
                            </tr>
                            <tr>
                                <td><b>Event Date:</b></td>
                                <td>{record?.guestsData?.event_date}</td>
                            </tr>
                            <tr>
                                <td><b>Event Time:</b></td>
                                <td>{record?.guestsData?.event_time}</td>
                            </tr>
                            <tr>
                                <td><b>Price:</b></td>
                                <td>{record?.guestsData?.price}</td>
                            </tr>
                            <tr>
                                <td><b>Attendees:</b></td>
                                <td>{record?.guestsData?.event_attendees}</td>
                            </tr>
                            <tr>
                                <td><b>Total:</b></td>
                                <td>{record?.guestsData?.total}</td>
                            </tr>
                            </table>
                            </div>
                    <div className="col-md-6">
                        <table className="table nowrap">
                            <tr>
                                <td style={{width:"45%"}}><b>Company Name:</b></td>
                                <td>{record?.guestsData?.company_name}</td>
                            </tr>
                            <tr>
                                <td><b>Head Guest Name:</b></td>
                                <td>{record?.guestsData?.first_name+" "+record?.guestsData?.last_name}</td>
                            </tr>
                            <tr>
                                <td><b>Email:</b></td>
                                <td>{record?.guestsData?.email}</td>
                            </tr>
                            <tr>
                                <td><b>Phone Number:</b></td>
                                <td>{record?.guestsData?.phone}</td>
                            </tr>
                            <tr>
                                <td><b>Address 1:</b></td>
                                <td>{record?.guestsData?.billing_address_one}</td>
                            </tr>
                            <tr>
                                <td><b>Address 2:</b></td>
                                <td>{record?.guestsData?.billing_address_two}</td>
                            </tr>
                            </table>
                            </div>
                            </div>

                            <br />

    <p style={{textAlign:"center"}}><b>Participated Guests</b></p>

            <div className="row w-100">
              <div className="mb-3 col-12 text-center">
                <div className="row">
                  <div className="col-xl-6 col-lg-6 col-sm-6 col-12 mb-3">
                    <div className="ml-0">
                      <div className="d-flex">
                        <h5 className="mt-2 mr-1">Search: </h5>
                        <Search
                          onSearch={(value) => {
                            setSearch(value);
                            setCurrentPage(1);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-sm-6 col-12 d-flex justify-content-end mb-3">
                    <div
                      style={{
                        color: 'black',
                        fontSize: '12px',
                        fontWeight: '300',
                        paddingTop: '0px',
                        paddingBottom: '0px',
                      }}
                      className="align-self-center"
                    >
                      <b>Rows per page :&nbsp;</b>
                    </div>
                    <div className="align-self-center">
                      <Dropdown>
                        <Dropdown.Toggle
                          variant="none"
                          id="dropdown-basic"
                          style={{
                            cursor: 'auto',
                            backgroundColor: 'white',
                            borderColor: '#d5dbe0',
                            paddingBottom: '3px',
                            paddingTop: '3px',
                          }}
                        >
                          {limit}&nbsp;<i class="fa fa-caret-down"></i>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          {limit !== 10 ? (
                            <>
                              <Dropdown.Item
                                onClick={() => {
                                  setlimit(10);
                                }}
                              >
                                10
                              </Dropdown.Item>
                            </>
                          ) : null}

                          {limit !== 20 ? (
                            <>
                              <Dropdown.Item
                                onClick={() => {
                                  setlimit(20);
                                }}
                              >
                                20
                              </Dropdown.Item>
                            </>
                          ) : null}

                          {limit !== 30 ? (
                            <>
                              <Dropdown.Item
                                onClick={() => {
                                  setlimit(30);
                                }}
                              >
                                30
                              </Dropdown.Item>
                            </>
                          ) : null}

                          {limit !== 50 ? (
                            <>
                              <Dropdown.Item
                                onClick={() => {
                                  setlimit(50);
                                }}
                              >
                                50
                              </Dropdown.Item>
                            </>
                          ) : null}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                </div>

                <div className="row ">
                  <div className="col-12">
                    <div className="table-responsive">
                      <Table striped bordered hover>
                        <thead>
                          <TableHeader
                            headers={Header}
                            onSorting={(field, order) =>
                              setSorting({ field, order })
                            }
                          />
                        </thead>
                        <tbody>
                          { commentsData.length > 0 ?
                          commentsData.map((e, i) => (
                            <tr>
                              <td>{i+1}</td>
                              <td>{e?.name+" "+e?.last_name}</td>
                              <td>{e?.email}</td>
                              <td>{e?.phone_number}</td>
                              <td>{e?.address}</td>
                              <td>{setDateFormat(e?.createdAt)}</td>
                             
                            </tr>
                           
                          ))
                          : 
                          <tr>
                          <td colSpan={6}>No Data Found</td>
                          </tr>
                        }
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>
                <div
                  className="mt-2 d-flex justify-content-sm-center justify-content-xs-center justify-content-lg-end"
                  style={{
                    overflowX: 'auto',
                  }}
                >
                  <Pagination
                    total={totalItems}
                    itemsPerPage={limit}
                    currentPage={currentPage}
                    onPageChange={(page) => setCurrentPage(page)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};

export default ParticipatedGuests;
